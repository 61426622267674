import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

interface Props {
  title?: string
  tooltip?: string
}

export class FieldSet extends React.Component<Props, {}> {
  render() {
    const { children, tooltip, title } = this.props
    return (
      <fieldset>
        {title && (
          <legend>
            <h3>
              {title}&nbsp;
              {tooltip && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={['hover', 'focus', 'click']}
                  rootClose
                  overlay={
                    <Tooltip id="title-tooltip" className="infobox-msg">
                      {tooltip}
                    </Tooltip>
                  }
                >
                  <span className="icon icon--info_24_outline" />
                </OverlayTrigger>
              )}
            </h3>
          </legend>
        )}
        {children}
      </fieldset>
    )
  }
}
