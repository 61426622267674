import React from 'react'
import { Control, Errors } from 'react-redux-form'
import { InputGroup, FormControl, HelpBlock } from 'react-bootstrap'

interface Props {
  name: string
  label?: string
  placeholder?: string
  type?: 'text' | 'tel'
  suffixAddon?: string
  prefixAddon?: string
  model?: string
  validators?: any
  messages?: any
  disabled?: boolean
  changeAction?: any
  parser?: any
}

interface AddonProps {
  suffixAddon?: string
  prefixAddon?: string
  type: string
}

const FormControlWithAddon = (props: AddonProps) => {
  return props.prefixAddon ? (
    <InputGroup>
      <InputGroup.Addon>{props.prefixAddon}</InputGroup.Addon>
      <FormControl type={props.type} />
    </InputGroup>
  ) : (
    <InputGroup>
      <FormControl type={props.type} />
      <InputGroup.Addon>{props.suffixAddon}</InputGroup.Addon>
    </InputGroup>
  )
}

const MyTextInput = (props: any) => (
  <div className="textfield textfield--float-label">
    <input className={`textfield-control ${props.value ? 'is-not-empty' : ''}`} {...props} />
    {props.label && (
      <label className="textfield-label" htmlFor={props.name.replace(/\./gi, '_')}>
        {props.label}
      </label>
    )}
  </div>
)

export class Text extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { name, validators, messages, type = 'text', disabled, changeAction, parser, label, placeholder } = this.props
    const model = this.props.model ? this.props.model : `.${name}`
    return (
      <div>
        <Control.text
          id={`${name.replace(/\./gi, '_')}`}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          changeAction={changeAction}
          model={model}
          name={name}
          component={MyTextInput}
          errors={validators}
          type={type}
          parser={parser}
        />
        {messages && (
          <Errors
            model={model}
            messages={messages}
            component={(props2) => (
              <div className="textfield-helper">
                <span className="checkbox-helper-message ">{props2.children}</span>
              </div>
            )}
            show={(field) => {
              return field.touched as boolean
            }}
          />
        )}
      </div>
    )
  }
}
