import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ThanksStep as ThanksStepComponent } from '../../ui/client/thanks-step.component'

import { setStep } from 'dhv-form'

import { AppState } from 'dhv-common'

const mapStateToProps = (state: AppState) => ({
  thanksStep: state.dhvForm.thanksStep,
  contractStep: state.dhvForm.contractStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const ThanksStep = connect(mapStateToProps, mapDispatchToProps)(ThanksStepComponent)
