import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'
import { SET_LOCKED } from '../actions/set-locked.action'
import { SET_UN_LOCKED } from '../actions/set-unlocked.action'

export const contractStepReducer = combineForms(
  {
    locked: (locked = dhvFormStateDefault.contractStep.locked, action: any) =>
      action.type === SET_LOCKED ? true : action.type === SET_UN_LOCKED ? false : locked,
    startDate: dhvFormStateDefault.contractStep.startDate,
    preExistingContracts: dhvFormStateDefault.contractStep.preExistingContracts,
    preDamages: dhvFormStateDefault.contractStep.preDamages,
    droneBuildYear: dhvFormStateDefault.contractStep.droneBuildYear,
    droneType: dhvFormStateDefault.contractStep.droneType,
    droneManufacturer: dhvFormStateDefault.contractStep.droneManufacturer,
    droneNumber: dhvFormStateDefault.contractStep.droneNumber,
  },
  'dhvForm.contractStep'
)
