import { ActionCreator } from 'redux'
export interface SetLockedAction {
  type: 'SET_LOCKED'
}
export const SET_LOCKED = 'SET_LOCKED'
export const setLocked: ActionCreator<SetLockedAction> = () => {
  return {
    type: SET_LOCKED,
  }
}
