import React from 'react'
import { FormGroup as FormGroupBoostrap, Col, ControlLabel } from 'react-bootstrap'

interface Props {
  groups: Array<{
    label: string | JSX.Element
    control: JSX.Element
    error?: boolean
    labelColumns?: { xs?: number; sm?: number; md?: number; lg?: number }
    controlColumns?: { xs?: number; sm?: number; md?: number; lg?: number }
  }>
}

export class FormGroupMulti extends React.Component<Props, {}> {
  render() {
    const { children, groups } = this.props
    return (
      <FormGroupBoostrap>
        <div className="row">
          {groups.map((group) => {
            const labelColumns = group.labelColumns || { xs: 2 }
            const controlColumns = group.controlColumns || { xs: 4 }
            return (
              <React.Fragment>
                <Col componentClass={ControlLabel} {...labelColumns} className={`${group.error ? 'has-error' : ''}`}>
                  {group.label}
                </Col>
                <Col {...controlColumns}>
                  {group.control}
                  {children}
                </Col>
              </React.Fragment>
            )
          })}
        </div>
      </FormGroupBoostrap>
    )
  }
}
