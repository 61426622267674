import React from 'react'
import { FormControl } from 'react-bootstrap'

interface Props {
  value: string | JSX.Element
}

export class Static extends React.Component<Props, {}> {
  render() {
    const { value } = this.props
    return <FormControl.Static>{value}</FormControl.Static>
  }
}
