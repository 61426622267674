import React from 'react'

import moment from 'moment'

moment.locale('de')

interface Props {
  id?: string

  onChange: (event: any) => any

  onFocus: () => any

  onKeyPress: () => any

  onBlur: () => any

  date: Date

  value: string

  className: string

  disabled: boolean
}

interface State {}

export class MobileDatePicker extends React.Component<Props, State> {
  id: string

  constructor(props: Props) {
    super(props)
    this.id = props.id ? props.id : Math.random().toString()
  }

  handleChange = (): any => {
    let date = (document.getElementById(this.id) as any).value
    date = date ? moment(date, 'YYYY-MM-DD').toDate() : undefined
    this.props.onChange(date)
  }

  render() {
    return (
      <input
        id={this.id}
        type="date"
        value={this.props.date instanceof Date ? moment(this.props.date).format('YYYY-MM-DD') : undefined}
        onChange={this.handleChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        className={this.props.className}
        disabled={this.props.disabled}
      />
    )
  }
}
