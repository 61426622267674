import update from 'immutability-helper'

import { dhvFormStateDefault } from 'dhv-common'
import { FINISH_FORM_SUCCESS } from 'dhv-form/api/client/actions/finish.action'
import { ThanksStepState } from 'client/dhv-form-thanks'

export function thanksStepReducer(state: ThanksStepState = dhvFormStateDefault.thanksStep, action: any): ThanksStepState {
  switch (action.type) {
    case FINISH_FORM_SUCCESS:
      if (action.payload && action.payload.data) {
        return update(state, {
          $set: {
            landingPage: action.payload.data.landingPage,
            vsnrHaftpflicht: action.payload.data.vsnrHaftpflicht,
            vsnrKasko: action.payload.data.vsnrKasko,
          },
        })
      }
    default:
      return state
  }
}
