import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

const defaultPerson = dhvFormStateDefault.paymentStep.directDebit.owner
export const ownerReducer = combineForms(
  {
    foa: defaultPerson.foa,
    title: defaultPerson.title,
    name: defaultPerson.name,
    firstname: defaultPerson.firstname,
    street: defaultPerson.street,
    postalCode: defaultPerson.postalCode,
    city: defaultPerson.city,
    email: defaultPerson.email,
  },
  'dhvForm.paymentStep.directDebit.owner'
)
