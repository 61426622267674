import { dhvFormStateDefault } from 'dhv-common'
import {
  CALC_HAFTPFLICHT_KASKO_PRICE_START,
  CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_KASKO_PRICE_FAILED,
  CALC_HAFTPFLICHT_PRICE_START,
  CALC_HAFTPFLICHT_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_PRICE_FAILED,
  RESET_PRICE,
} from '../actions/prices'

export function priceBrutto(state: number = dhvFormStateDefault.rateStep.haftpflicht.priceBrutto, action: any): number {
  switch (action.type) {
    case CALC_HAFTPFLICHT_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_PRICE_SUCCESS:
      return action.priceBruttoHaftpflicht
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceBruttoHaftpflicht
    case CALC_HAFTPFLICHT_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    case RESET_PRICE:
      return 0
    default:
      return state
  }
}

export function priceNetto(state: number = dhvFormStateDefault.rateStep.haftpflicht.priceNetto, action: any): number {
  switch (action.type) {
    case CALC_HAFTPFLICHT_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_PRICE_SUCCESS:
      return action.priceNettoHaftpflicht
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceNettoHaftpflicht
    case CALC_HAFTPFLICHT_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    case RESET_PRICE:
      return 0
    default:
      return state
  }
}

export function priceTax(state: number = dhvFormStateDefault.rateStep.haftpflicht.priceTax, action: any): number {
  switch (action.type) {
    case CALC_HAFTPFLICHT_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_PRICE_SUCCESS:
      return action.priceTaxHaftpflicht
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceTaxHaftpflicht
    case CALC_HAFTPFLICHT_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    case RESET_PRICE:
      return 0
    default:
      return state
  }
}
