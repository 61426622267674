export interface DirectDebit {
  iban: string

  bic: string

  owner: any

  forms: any

  sameOwner: string
}

export function secureIban(number: string) {
  return `******************${number.substr(number.length - 4)}`
}
