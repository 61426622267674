import './person-step.css'
import moment from 'moment'

import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Control, Errors } from 'react-redux-form'
import { HelpBlock } from 'react-bootstrap'
import MobileDetect from 'mobile-detect'
import { ActionCreator } from 'redux'

import { Checkbox, FieldSet, FieldSetContent, FormGroup, FormGroupMulti, Text, Radio } from 'zur-ui-forms'
import { DatePicker } from 'zur-ui-datepicker'
import { SetStepAction } from 'dhv-form'

import { PersonStep as PersonStepState } from '../../api/client/state/person-step.state'
import { PersonStepSchema } from '../../api/client/state/person-step.schema'
import { controlColumnsFull, labelColumnsZero } from 'zur-ui-forms/columns-config'

interface PersonStepProps extends RouteComponentProps<{}> {
  personStep: PersonStepState
  isBusiness: boolean
  setStep: ActionCreator<SetStepAction>
}

interface UIState {
  isGift: boolean
}

class PersonStep extends React.Component<PersonStepProps, UIState> {
  mobileDetect: MobileDetect

  constructor(props: PersonStepProps) {
    super(props)
    this.mobileDetect = new MobileDetect(window.navigator.userAgent)
  }

  showError = (field: any) => {
    return field.touched && !field.valid
  }

  onSubmit = () => {
    this.props.setStep(3)
    this.props.history.push('/zahlungsinformationen')
    return false
  }

  back = () => {
    this.props.history.push('/vertrags-grundlagen')
  }

  render() {
    const { personStep, isBusiness } = this.props
    const validators = PersonStepSchema.getValidators(personStep, {
      context: personStep,
      autoClean: true,
    })

    return (
      <div>
        <Form
          model="dhvForm.personStep"
          className="form-horizontal insurance-form"
          onSubmit={this.onSubmit}
          validators={{
            '': {
              emailsMatch: (val: any) => {
                return val.emailConfirmation === val.email
              },
            },
            agreed: { agreed: (val) => val },
            german: { german: (val) => val },
          }}
        >
          <FieldSet title="Allgemeine Angaben">
            <FieldSetContent>
              {!isBusiness && (
                <FormGroup error={this.showError(personStep.forms.foa)}>
                  <Control.radio
                    model=".foa"
                    label="Anrede*"
                    value={personStep.foa}
                    name="foa"
                    component={Radio}
                    errors={validators.foa}
                    controlProps={{
                      options: [
                        { label: 'Herr', value: 'Herr' },
                        { label: 'Frau', value: 'Frau' },
                      ],
                      validators: validators.foa,
                      messages: {
                        required: 'Bitte wählen',
                      },
                    }}
                  />
                </FormGroup>
              )}
              {isBusiness && (
                <FormGroup error={this.showError(personStep.forms.foa)}>
                  <Control.radio
                    model=".foa"
                    label="Anrede*"
                    value={personStep.foa}
                    name="foa"
                    component={Radio}
                    errors={validators.foa}
                    controlProps={{
                      options: [
                        { label: 'Herr', value: 'Herr' },
                        { label: 'Frau', value: 'Frau' },
                        { label: 'Firma', value: 'Firma' },
                      ],
                      validators: validators.foa,
                      messages: {
                        required: 'Bitte wählen',
                      },
                    }}
                  />
                </FormGroup>
              )}
              {(!isBusiness || personStep.foa === 'Herr' || personStep.foa === 'Frau') && (
                <div>
                  <FormGroup>
                    <Text model="dhvForm.personStep.title" label="Titel" name="title" />
                  </FormGroup>
                  <FormGroup error={this.showError(personStep.forms.firstname)}>
                    <Text
                      model="dhvForm.personStep.firstname"
                      label="Vorname*"
                      name="firstname"
                      validators={validators.firstname}
                      messages={{
                        required: 'Bitte einen Vornamen angeben',
                        max: 'Der Vorname darf nicht länger als 70 Zeichen sein',
                        regEx: 'Der Vorname darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
                      }}
                    />
                  </FormGroup>
                </div>
              )}
              <FormGroup error={this.showError(personStep.forms.name)}>
                <Text
                  model="dhvForm.personStep.name"
                  label={personStep.foa === 'Firma*' ? 'Firmenname*' : 'Name*'}
                  name="name"
                  validators={validators.name}
                  messages={{
                    required: 'Bitte einen Namen angeben',
                    max: 'Der Name darf nicht länger als 70 Zeichen sein',
                    regEx: 'Der Name darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
                  }}
                />
              </FormGroup>
              {(!isBusiness || personStep.foa !== 'Firma') && (
                <FormGroup label="Geburtsdatum*" error={this.showError(personStep.forms.birthdate)}>
                  <DatePicker
                    model=".birthdate"
                    validators={validators.birthdate}
                    messages={{
                      required: 'Bitte ein Geburtsdatum angeben',
                      custom: 'Der Versicherungsnehmer muss mindestens 18 Jahre alt sein.',
                    }}
                    maxDate={moment().subtract(18, 'year').toDate()}
                  />
                </FormGroup>
              )}
              <FormGroup error={this.showError(personStep.forms.street)}>
                <Text
                  model="dhvForm.personStep.street"
                  label="Straße / Nr.*"
                  name="street"
                  validators={validators.street}
                  messages={{
                    required: 'Bitte Straße und Hausnummer angeben',
                  }}
                />
              </FormGroup>
              <FormGroup error={this.showError(personStep.forms.postalCode)}>
                <Text
                  model="dhvForm.personStep.postalCode"
                  label="PLZ*"
                  name="postalCode"
                  validators={validators.postalCode}
                  messages={{
                    required: 'Bitte eine gültige Postleitzahl angeben',
                    type: 'Bitte eine gültige Postleitzahl angeben',
                    min: 'Bitte eine gültige deutsche Postleitzahl angeben',
                    max: 'Bitte eine gültige deutsche Postleitzahl angeben',
                  }}
                />
              </FormGroup>
              <FormGroup error={this.showError(personStep.forms.city)}>
                <Text
                  model="dhvForm.personStep.city"
                  label="Ort*"
                  name="city"
                  validators={validators.city}
                  messages={{
                    required: 'Bitte einen Wohnort angeben',
                  }}
                />
              </FormGroup>
              <FormGroup labelColumns={labelColumnsZero} controlColumns={controlColumnsFull}>
                <Control.checkbox
                  model="dhvForm.personStep.german"
                  component={Checkbox}
                  controlProps={{
                    id: 'german',
                    label: 'Hiermit bestätige ich, dass mein dauerhafter Wohnsitz in Deutschland ist.',
                  }}
                />
                <Errors
                  model="dhvForm.personStep.german"
                  messages={{
                    german: 'Ein deutscher Wohnsitz ist erforderlich um den Antrag abschließen zu können.',
                  }}
                  component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
                  show={(field) => {
                    return field.touched as boolean
                  }}
                />
              </FormGroup>
              <FormGroup error={this.showError(personStep.forms.phone)}>
                <Text
                  model="dhvForm.personStep.phone"
                  label="Telefon"
                  name="phone"
                  validators={validators.phone}
                  messages={{
                    regEx:
                      'Die Telefonnummer darf nur die Zeichen 0-9, "/", "(", ")" enthalten und muss mit "+" oder "0" beginnen.',
                  }}
                />
              </FormGroup>
              <FormGroup error={this.showError(personStep.forms.email)}>
                <Text
                  model="dhvForm.personStep.email"
                  label="E-Mail*"
                  name="email"
                  validators={validators.email}
                  messages={{
                    required: 'Bitte eine gültige E-Mailadresse angeben',
                    regEx: 'Bitte eine gültige E-Mailadresse angeben',
                  }}
                />
              </FormGroup>
              <FormGroup error={personStep.forms.emailConfirmation.touched && personStep.forms.$form.errors.emailsMatch}>
                <Text model="dhvForm.personStep.emailConfirmation" label={`E-Mail bestätigen*`} name="emailConfirmation" />
                <Errors
                  model={`dhvForm.personStep`}
                  messages={{
                    emailsMatch: 'E-Mailadressen müssen übereinstimmen',
                  }}
                  component={(props) => <HelpBlock>{props.children}</HelpBlock>}
                  show={() => {
                    return personStep.forms.emailConfirmation.touched
                  }}
                />
              </FormGroup>
              {isBusiness && (
                <FormGroup label="Vorsteuerabzugsberechtigt*" error={this.showError(personStep.forms.vsab)}>
                  <Control.radio
                    model=".vsab"
                    value={personStep.vsab}
                    name="vsab"
                    component={Radio}
                    errors={validators.vsab}
                    controlProps={{
                      options: [
                        { label: 'Ja', value: 'Ja' },
                        { label: 'Nein', value: 'Nein' },
                      ],
                      messages: {
                        required: 'Bitte wählen',
                      },
                    }}
                  />
                </FormGroup>
              )}
              {isBusiness && personStep.vsab === 'Ja' && (
                <FormGroup error={this.showError(personStep.forms.vsabValue)}>
                  <Text
                    model="dhvForm.personStep.vsabValue"
                    label="Höhe in %*"
                    name="vsabValue"
                    validators={validators.vsabValue}
                    messages={{
                      custom: 'Bitte die Höhe der Vorsteuerabzugsberechtigung in % angeben',
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup labelColumns={labelColumnsZero} controlColumns={controlColumnsFull}>
                <p className="emailMarginTop">
                  <strong>
                    Möchten Sie zukünftig mit uns ganz einfach per E-Mail kommunizieren? Dafür benötigen wir Ihr Einverständnis.
                  </strong>
                </p>
                <p>
                  Zum Schutz Ihrer Daten nutzt die Zurich Insurance plc Niederlassung für Deutschland (NfD) eine
                  Transportverschlüsselung (TLS), wenn diese Technik von Ihrem E-Mail-Provider unterstützt wird. Unter{' '}
                  <a href="https://www.zurich.de/emailinfo">www.zurich.de/emailinfo</a> gibt es hierzu nähere Informationen sowie
                  zu weiteren Möglichkeiten (z.B. auch zur Ende-zu-Ende-Verschlüsselung), Ihre Vertragspost schnell und bequem zu
                  erhalten. Dokumente mit Gesundheitsdaten verschickt die Zurich Insurance plc NfD weiterhin auf dem Postweg.
                </p>
                <p>
                  Die Einwilligung können Sie jederzeit ohne Einfluss auf die Geschäftsbeziehung für die Zukunft widerrufen, z.B.
                  unter <a href="https://www.zurich.de/einwilligung">www.zurich.de/einwilligung</a>.
                </p>
                <Control.checkbox
                  model="dhvForm.personStep.agreed"
                  component={Checkbox}
                  controlProps={{
                    id: 'agreed',
                    children: (
                      <span>
                        <strong>Ich willige ein,</strong> dass mir die Zurich Insurance plc NfD zu meinen
                        Sachversicherungsverträgen, z.B. Versicherungsschein, Beitragsrechnungen, Schadenkorrespondenz, besonders
                        geschützte Informationen der Unfallversicherung und sonstige Vertragskorrespondenz anstatt per Brief
                        künftig per E-Mail an die oben angegebene E-Mail-Adresse, auf die ich Zugriff habe, zusenden darf. Über
                        etwaige Änderungen meiner E-Mail-Adresse werde ich die Zurich Insurance plc NfD rechtzeitig informieren.
                        Die Zustimmung gilt für <strong>alle</strong> mit der Zurich Insurance plc NfD geschlossenen Verträge.
                      </span>
                    ),
                  }}
                />
                <Errors
                  model="dhvForm.personStep.agreed"
                  messages={{
                    agreed: 'Die Einwilligung ist erforderlich um den Antrag abschließen zu können.',
                  }}
                  component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
                  show={(field) => {
                    return field.touched as boolean
                  }}
                />
              </FormGroup>
              <FormGroup labelColumns={labelColumnsZero} controlColumns={controlColumnsFull}>
                <Control.checkbox
                  model=".postalDelivery"
                  checked={personStep.postalDelivery}
                  name="postalDelivery"
                  component={Checkbox}
                  controlProps={{
                    id: 'postalDelivery',
                    children: (
                      <span>
                        Ich wünsche zusätzlich zur oben stehenden Regelung den Versand der Vertragsunterlagen (z.B.
                        Versicherungsschein, Rechnungen) in Papierform.
                      </span>
                    ),
                  }}
                />
              </FormGroup>
              <p className="sub-note">mit * gekennzeichnete Felder sind Pflichtfelder</p>
            </FieldSetContent>
          </FieldSet>
          <FieldSet>
            <button className="btn btn--text btn-prev float-left" type="button" onClick={this.back}>
              Zurück
              <span className="icon icon--arrow-left_24 float-left" />
            </button>
            <button className="btn btn--primary btn-next float-right" type="submit">
              <span className="icon i-cta" />
              Weiter
            </button>
          </FieldSet>
        </Form>
      </div>
    )
  }
}

const wrapped = withRouter<PersonStepProps>(PersonStep)
export { wrapped as PersonStep }
