import { Schema } from 'common/dots-schema'
import iban from 'iban'

export const DirectDebitSchemaModel = {
  brand: {
    type: String,
    optional: true,
  },
  iban: {
    type: String,
    custom: (value: string) => {
      if (!value || value.length === 0) {
        return null
      }
      return iban.isValid(value) ? null : 'iban is invalid'
    },
  },
  bic: {
    type: String,
    optional: true,
  },
  sameOwner: {
    type: String,
  },
}

export const DirectDebitSchema = new Schema(DirectDebitSchemaModel)
