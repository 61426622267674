import { createStore, compose, applyMiddleware } from 'redux'
import ReduxPromise from 'redux-promise'
import persistState from 'redux-localstorage'
import thunk from 'redux-thunk'

import { reducers } from './main.reducer'
import { persist, resetStorage } from 'dhv-common'

import { AppState } from 'dhv-common'

if (window.location.search.indexOf('new') !== -1) {
  resetStorage()
  window.location.search = ''
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(reducers, composeEnhancers(applyMiddleware(ReduxPromise), applyMiddleware(thunk), persist))
