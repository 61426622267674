import { Schema } from 'common/dots-schema'

export const ContractStepSchemaModelNonBusiness = {
  startDate: {
    type: Date,
  },
  preExistingContracts: {
    type: String,
  },
  preDamages: {
    type: String,
  },
}

export const ContractStepSchemaModel = {
  ...ContractStepSchemaModelNonBusiness,
  droneBuildYear: {
    type: Number,
  },
  droneManufacturer: {
    type: String,
    max: 29,
  },
  droneType: {
    type: String,
    max: 29,
  },
  droneNumber: {
    type: String,
    max: 24,
  },
}

export const ContractStepSchema = new Schema(ContractStepSchemaModel)
export const ContractStepSchemaNonBusiness = new Schema(ContractStepSchemaModelNonBusiness)
