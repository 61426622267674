import { Schema } from 'common/dots-schema'

export const CompleteStepSchemaModel = {
  brokerId: {
    type: String,
    optional: true,
    regEx: /^\d{9}$/,
  },
  brokerEmail: {
    type: String,
    optional: true,
    regEx: Schema.RegEx.Email,
  },
  brokerEmail2: {
    type: String,
    optional: true,
    regEx: Schema.RegEx.Email,
  },
  milesAndMore: {
    type: String,
    optional: true,
    regEx: /^\d{15}$/,
  },
  acceptedContact: {
    type: Boolean,
  },
  acceptedPhone: {
    type: Boolean,
  },
  acceptedEmail: {
    type: Boolean,
  },
  acceptedMobile: {
    type: Boolean,
  },
  acceptedSms: {
    type: Boolean,
  },
}

export const CompleteStepSchema = new Schema(CompleteStepSchemaModel)
