import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

const defaultPerson = dhvFormStateDefault.personStep
export const personStep = combineForms(
  {
    foa: defaultPerson.foa,
    title: defaultPerson.title,
    name: defaultPerson.name,
    firstname: defaultPerson.firstname,
    birthdate: defaultPerson.birthdate,
    street: defaultPerson.street,
    streetNumber: defaultPerson.streetNumber,
    postalCode: defaultPerson.postalCode,
    city: defaultPerson.city,
    phone: defaultPerson.phone,
    email: defaultPerson.email,
    emailConfirmation: defaultPerson.emailConfirmation,
    vsab: defaultPerson.vsab,
    vsabValue: defaultPerson.vsabValue,
    agreed: false,
    postalDelivery: defaultPerson.postalDelivery,
    german: false,
  },
  'dhvForm.personStep'
)
