import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AppState } from 'dhv-common'

import { ROUTES } from '../../../ui/client/dhv-form.component'

const mapStateToProps = (state: AppState) => ({
  steps: state.dhvForm.steps,
})

export const stepRedirect = (WrappedComponent: any, step: number) => {
  const StepRedirect = class extends React.Component<any, {}> {
    render() {
      const { steps } = this.props

      if (step > steps.current && !steps.locked) {
        switch (steps.current) {
          case 0:
            return <Redirect to={ROUTES.TARIF_RECHNER} />
          case 1:
            return <Redirect to={ROUTES.VERTRAGS_GRUNDLAGEN} />
          case 2:
            return <Redirect to={ROUTES.ALLGEMEINE_ANGABEN} />
          case 3:
            return <Redirect to={ROUTES.ZAHLUNG} />
          case 4:
            return <Redirect to={ROUTES.VERTRAGS_UNTERLAGEN} />
          case 5:
            return <Redirect to={ROUTES.VERTRAGS_ABSCHLUSS} />
          case 6:
            return <Redirect to={ROUTES.THANKS} />
        }
      }

      return <WrappedComponent {...this.props} />
    }
  }

  return connect(mapStateToProps)(StepRedirect)
}
