import _ from 'lodash'
import moment from 'moment'

export const persist = (next: Function) => (reducer: any, initialState: any, enhancer: any) => {
  if (typeof initialState === 'function' && typeof enhancer === 'undefined') {
    enhancer = initialState
    initialState = undefined
  }

  try {
    const stateString = localStorage.getItem('state')
    if (stateString) {
      const persistedState = JSON.parse(stateString)
      if (moment(persistedState.date).add(15, 'minutes').isAfter(moment())) {
        initialState = _.merge(initialState || {}, persistedState.data)
      }
    }
  } catch (e) {
    console.warn('Failed to retrieve initialize state from localStorage:', e)
  }

  const store = next(reducer, initialState, enhancer)
  let throttle: null | NodeJS.Timer = null
  store.subscribe(() => {
    if (throttle) {
      clearInterval(throttle)
    }
    throttle = setTimeout(() => {
      const state = store.getState()
      localStorage.setItem(
        'state',
        JSON.stringify({
          date: new Date(),
          data: state,
        })
      )
    }, 500)
  })

  return store
}

export function resetStorage() {
  localStorage.setItem(
    'state',
    JSON.stringify({
      date: new Date(),
      data: {},
    })
  )
}
