import React from 'react'
import { Control, Errors } from 'react-redux-form'
import { HelpBlock } from 'react-bootstrap'
import MobileDetect from 'mobile-detect'

import { ReactDatePicker } from './react-date-picker.component'
import { MobileDatePicker } from './mobile-date-picker.component'

interface Props {
  model: string

  messages?: any

  validators?: any

  disabled?: boolean

  placeholder?: string

  minDate?: Date

  maxDate?: Date
}

interface State {}

export class DatePicker extends React.Component<Props, State> {
  static defaultProps = {
    placeholder: 'tt.mm.jjjj',
    disabled: false,
  }

  mobileDetect: MobileDetect

  constructor(props: Props) {
    super(props)
    this.mobileDetect = new MobileDetect(window.navigator.userAgent)
  }

  render() {
    const { validators, disabled, placeholder, model, messages, minDate, maxDate } = this.props
    const errors = (
      <Errors
        model={model}
        messages={messages}
        component={(props) => <HelpBlock>{props.children}</HelpBlock>}
        show={(field) => {
          return field.touched as boolean
        }}
      />
    )

    if (this.mobileDetect.mobile()) {
      return (
        <span>
          <Control.text
            model={model}
            component={MobileDatePicker}
            controlProps={{
              className: 'textfield-control',
              placeholder: placeholder,
            }}
            errors={validators}
            mapProps={{
              date: (props) => {
                if (props.modelValue && typeof props.modelValue === 'string') {
                  return new Date(props.modelValue)
                } else {
                  return props.modelValue
                }
              },
            }}
          />
          {errors}
        </span>
      )
    }
    // form-control
    return (
      <span>
        <Control.text
          model={model}
          component={ReactDatePicker}
          controlProps={{
            className: 'textfield-control datepicker-input is-not-empty',
            disabled: disabled,
            placeholder: placeholder,
            minDate: minDate,
            maxDate: maxDate,
          }}
          errors={validators}
          mapProps={{
            date: (props) => {
              if (props.modelValue && typeof props.modelValue === 'string') {
                return new Date(props.modelValue)
              } else {
                return props.modelValue
              }
            },
          }}
        />
        {errors}
      </span>
    )
  }
}
