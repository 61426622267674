import React from 'react'
import { Control, Errors } from 'react-redux-form'

interface Props {
  name: string
  label?: string
  placeholder?: string
  type?: 'text' | 'tel'
  suffixAddon?: string
  prefixAddon?: string
  model?: string
  validators?: any
  messages?: any
  disabled?: boolean
  changeAction?: any
  parser?: any
}

const MySelectInput = (props: any) => (
  <div className={`textfield textfield-select textfield--float-label ss-main`} onClick={props.onClick}>
    {props.label && (
      <label className="textfield-label" htmlFor={`${props.name}`}>
        {props.label}
      </label>
    )}
    <select
      {...props}
      className={`textfield-control dropdown-select initialized ss-single-selected ${props.disabled ? 'ss-disabled' : ''}`}
      tabIndex={-1}
    >
      {props.children}
    </select>
    <div className="ss-single-selected" style={{ pointerEvents: 'none' }}>
      <span className="ss-arrow">
        <span className={`arrow-${props.open ? 'up' : 'down'}`}></span>
      </span>
    </div>
  </div>
)

interface State {
  open: boolean
}

export class Select extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  render() {
    const { name, validators, messages, disabled, changeAction, parser, label, placeholder, children } = this.props
    const model = this.props.model ? this.props.model : `.${name}`

    return (
      <div>
        <Control.select
          id={`${name}`}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          changeAction={changeAction}
          model={model}
          name={name}
          component={MySelectInput}
          errors={validators}
          parser={parser}
          open={this.state.open}
          onClick={(e: any) => {
            this.setState({ open: !this.state.open })
          }}
          children={children}
        />
        {messages && (
          <Errors
            model={model}
            messages={messages}
            component={(props2) => (
              <div className="textfield-helper">
                <span className="checkbox-helper-message ">{props2.children}</span>
              </div>
            )}
            show={(field) => {
              return field.touched as boolean
            }}
          />
        )}
      </div>
    )
  }
}
