import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router'
import { Form } from 'react-redux-form'
import { ActionCreator } from 'redux'

import { SetStepAction } from 'dhv-form'
import { ThanksStepState } from 'dhv-form-thanks'
import { ContractStepState } from 'dhv-form-contract'
import moment from 'moment'

interface ThanksStepProps extends RouteComponentProps<{}> {
  thanksStep: ThanksStepState
  contractStep: ContractStepState
  setStep: ActionCreator<SetStepAction>
}

class ThanksStep extends React.Component<ThanksStepProps, ThanksStepState> {
  onRestart = () => {
    window.location.assign(window.location.origin + '/?new')
  }

  onSubmit = () => {
    window.location.assign(this.props.thanksStep.landingPage)
    return false
  }

  render() {
    const { contractStep, thanksStep } = this.props

    const haftpflichtKasko = thanksStep.vsnrHaftpflicht !== '' && thanksStep.vsnrKasko !== ''

    return (
      <div className="form-horizontal insurance-form">
        <fieldset>
          <div className="form-content center">
            <legend>
              <h3>Herzlichen Glückwunsch zu Ihrer neuen Versicherung</h3>
              <h4>Wie es jetzt weitergeht:</h4>
            </legend>
            <div className="form-group">
              <p>
                <span className="icon circle">1</span>
              </p>
              <p>Ihre Versicherungsdokumente wurden soeben per E-Mail an Sie versendet.</p>
            </div>
            <div className="form-group">
              <p>
                <span className="icon circle">2</span>
              </p>
              <p>Speichern Sie die Dokumente aus der Email sicher ab.</p>
            </div>

            <div className="form-group">
              <p>
                <span className="icon circle">3</span>
              </p>
              <p>
                Der Versicherungsbeginn ist der{' '}
                <b>{contractStep.startDate ? moment(contractStep.startDate).format('DD.MM.YYYY') : 'error'}</b>
              </p>
              <p>
                <span>Die Versicherungsnummer{haftpflichtKasko ? 'n lauten' : ' lautet'}</span>
                <br />
                {thanksStep.vsnrHaftpflicht !== '' && (
                  <span>
                    Haftpflicht: {<b>{thanksStep.vsnrHaftpflicht}</b>}
                    <br />
                  </span>
                )}
                {thanksStep.vsnrKasko !== '' && (
                  <span>
                    Kasko: {<b>{thanksStep.vsnrKasko}</b>}
                    <br />
                  </span>
                )}
              </p>
            </div>
            <div>
              <button className="btn btn--primary btn-next" onClick={this.onSubmit} type="button">
                <span className="icon i-cta" />
                Weiter zur Produktseite
              </button>
            </div>
            <div>
              <button className="btn btn--secondary" onClick={this.onRestart} type="button">
                <span className="icon i-cta" />
                Eine weitere Versicherung abschließen
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}

const wrapped = withRouter<ThanksStepProps>(ThanksStep)
export { wrapped as ThanksStep }
