export const FINISH_FORM_SUCCESS = 'FINISH_FORM_SUCCESS'
export const FINISH_FORM_FAILED = 'FINISH_FORM_FAILED'
export const START_FINISH_FORM = 'START_FINISH_FORM'
export const RESET_FINISH_FORM = 'RESET_FINISH_FORM'

import axios from 'axios'
import moment from 'moment'
import { ActionCreator } from 'redux'

import { DhvFormState } from 'dhv-form'
import { DroneInsuranceCheckout, DroneInsuranceCheckoutWrapper } from 'common/drone-insurance'

function calcEnddate(startDate: Date) {
  return moment(startDate).add(1, 'year').toDate()
}

function fixDate(date: Date | null | undefined) {
  if (!date) return date
  return moment(date).hour(11).toDate()
}

export const finish = (form: DhvFormState) => {
  return async (dispatch: any) => {
    const insurance: DroneInsuranceCheckout = {
      business: form.rateStep.usage === 'g',
      scope: form.rateStep.scope,
      startDate: fixDate(form.contractStep.startDate) as Date,
      endDate: fixDate(calcEnddate(form.contractStep.startDate as Date)) as Date,
      preDamages: form.contractStep.preDamages === 'ja',
      preExistingContracts: form.contractStep.preExistingContracts === 'ja',
      droneBuildYear: form.contractStep.droneBuildYear as string,
      droneManufacturer: form.contractStep.droneManufacturer,
      droneNumber: form.contractStep.droneNumber,
      droneType: form.contractStep.droneType,
      kasko:
        form.rateStep.scope === 'kasko' || form.rateStep.scope === 'haftpflichtKasko'
          ? {
              dronePrice: form.rateStep.kasko.dronePrice,
              droneLending: form.rateStep.kasko.droneLending,
              priceBrutto: form.rateStep.kasko.priceBrutto,
              priceNetto: form.rateStep.kasko.priceNetto,
              priceTax: form.rateStep.kasko.priceTax,
            }
          : undefined,
      haftpflicht:
        form.rateStep.scope === 'haftpflicht' || form.rateStep.scope === 'haftpflichtKasko'
          ? {
              coverage: form.rateStep.haftpflicht.coverage,
              insuranceSum: form.rateStep.haftpflicht.insuranceSum,
              deductible: form.rateStep.haftpflicht.deductible,
              priceBrutto: form.rateStep.haftpflicht.priceBrutto,
              priceNetto: form.rateStep.haftpflicht.priceNetto,
              priceTax: form.rateStep.haftpflicht.priceTax,
            }
          : undefined,
      owner: {
        foa: form.personStep.foa,
        title: form.personStep.title,
        name: form.personStep.name,
        firstname: form.personStep.firstname,
        birthdate: fixDate(form.personStep.birthdate),
        street: form.personStep.street,
        postalCode: form.personStep.postalCode,
        city: form.personStep.city,
        phone: form.personStep.phone,
        email: form.personStep.email,
        emailConfirmation: form.personStep.emailConfirmation,
        vsab: form.personStep.vsab === 'Ja',
        vsabValue: form.personStep.vsab === 'Ja' ? parseInt(form.personStep.vsabValue as string, 10) : undefined,
        postalDelivery: form.personStep.postalDelivery,
      },
      payment: {
        iban: form.paymentStep.directDebit.iban,
        bic: form.paymentStep.directDebit.bic,
        sameOwner: form.paymentStep.directDebit.sameOwner === 'ja',
      },
      differingPayer:
        form.paymentStep.directDebit.sameOwner === 'ja'
          ? undefined
          : {
              foa: form.paymentStep.directDebit.owner.foa,
              title: form.paymentStep.directDebit.owner.foa !== 'Firma' ? form.paymentStep.directDebit.owner.title : '',
              name: form.paymentStep.directDebit.owner.name,
              firstname: form.paymentStep.directDebit.owner.foa !== 'Firma' ? form.paymentStep.directDebit.owner.firstname : '',
              street: form.paymentStep.directDebit.owner.street,
              postalCode: form.paymentStep.directDebit.owner.postalCode,
              city: form.paymentStep.directDebit.owner.city,
              email: form.paymentStep.directDebit.owner.email,
              phone: form.paymentStep.directDebit.owner.phone,
            },
    }

    axios
      .post(`${process.env.API_HOST || ''}/api/insuranceCheckout`, {
        insurance,
        brokerId: form.completeStep.brokerId,
        brokerEmail: form.completeStep.brokerEmail,
        brokerEmail2: form.completeStep.brokerEmail2,
        milesAndMore: form.completeStep.milesAndMore,
        acceptedContact: form.completeStep.acceptedContact,
        acceptedEmail: form.completeStep.acceptedContact && form.completeStep.acceptedEmail,
        acceptedPhone: form.completeStep.acceptedContact && form.completeStep.acceptedPhone,
        acceptedMobile: form.completeStep.acceptedContact && form.completeStep.acceptedMobile,
        acceptedSms: form.completeStep.acceptedContact && form.completeStep.acceptedSms,
      } as DroneInsuranceCheckoutWrapper)
      .then(function (response) {
        return dispatch({
          type: FINISH_FORM_SUCCESS,
          payload: response,
        })
      })
      .catch(function (error) {
        return dispatch({
          type: FINISH_FORM_FAILED,
        })
      })
  }
}

export interface StartFinishAction {
  type: 'START_FINISH_FORM'
}

export const startFinish: ActionCreator<StartFinishAction> = () => {
  return {
    type: START_FINISH_FORM,
  }
}

export interface ResetFinishAction {
  type: 'RESET_FINISH_FORM'
}

export const resetFinish: ActionCreator<ResetFinishAction> = () => {
  return {
    type: RESET_FINISH_FORM,
  }
}
