import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { bindActionCreators, Dispatch } from 'redux'
import { finish, startFinish, resetFinish, setStep } from 'dhv-form'
import { CompleteStep as CompleteStepComponent } from '../../ui/client/complete-step.component'
import { AppState } from 'dhv-common'
import { setLocked } from 'dhv-form-contract/api/client/actions/set-locked.action'
import { setUnlocked } from 'dhv-form-contract/api/client/actions/set-unlocked.action'

const mapStateToProps = (state: AppState) => ({
  dhvForm: state.dhvForm,
  completeStep: state.dhvForm.completeStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
      finish,
      startFinish: startFinish,
      resetFinish: resetFinish,
    },
    dispatch
  )

  return {
    ...boundActionCreators,

    setBrokerId: (brokerId: string) => actions.change('dhvForm.completeStep.brokerId', brokerId),
    setBrokerEmail: (brokerEmail: string) => actions.change('dhvForm.completeStep.brokerEmail', brokerEmail),
  }
}

export const CompleteStep = connect(mapStateToProps, mapDispatchToProps)(CompleteStepComponent)
