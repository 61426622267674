import React from 'react'
import { FormGroup as FormGroupBoostrap, Col, ControlLabel } from 'react-bootstrap'

interface Props {
  label?: string | JSX.Element
  boldLabel: boolean
  error?: boolean
  labelColumns?: { xs?: number; sm?: number; md?: number; lg?: number }
  controlColumns?: { xs?: number; sm?: number; md?: number; lg?: number }
  icon?: boolean
}

export class FormGroup extends React.Component<Props, {}> {
  static defaultProps = {
    error: false,
    labelColumns: {
      sm: 12,
      lg: 12,
    },
    controlColumns: {
      sm: 8,
      lg: 6,
    },
    boldLabel: false,
  }

  render() {
    const { label, children, error, labelColumns, controlColumns, boldLabel, icon } = this.props

    return (
      <FormGroupBoostrap className={`${error ? 'has-error' : ''} row ${icon ? 'has-icon' : ''}`}>
        {label !== null && (
          <Col
            className={boldLabel ? 'boldLabel' : ''}
            componentClass={ControlLabel}
            {...{ ...FormGroup.defaultProps.labelColumns, ...labelColumns }}
          >
            {label}
          </Col>
        )}
        <Col {...{ ...FormGroup.defaultProps.controlColumns, ...controlColumns }}>{children}</Col>
      </FormGroupBoostrap>
    )
  }
}
