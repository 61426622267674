import moment from 'moment'
import { Schema } from 'common/dots-schema'

export const PersonStepSchemaModel = {
  foa: {
    type: String,
    max: 5,
  },
  title: {
    type: String,
    max: 70,
    optional: true,
  },
  name: {
    type: String,
    max: 70,
  },
  firstname: {
    type: String,
    max: 70,
  },
  birthdate: {
    type: Date,
    custom: (value: Date) => {
      if (value instanceof Date && moment(value).add(moment.duration(18, 'years')).isAfter(moment())) {
        return 'age must be at leat 18'
      }
      return null
    },
  },
  street: {
    type: String,
    max: 70,
  },
  postalCode: {
    type: String,
    max: 5,
    min: 5,
  },
  city: {
    type: String,
    max: 60,
  },
  phone: {
    type: String,
    max: 20,
    optional: true,
    regEx: /^(:?\+|0)[\/\d\s()]*$/,
  },
  email: {
    type: String,
    max: 70,
    regEx: Schema.RegEx.Email,
  },
  vsab: {
    type: String,
  },
  vsabValue: {
    type: String,
    optional: true,
    custom: (value: Date, personStep: any) => {
      if (!value && personStep.vsab === 'Ja') {
        return 'required'
      }
      return null
    },
  },
}

export const PersonStepSchema = new Schema(PersonStepSchemaModel)
export const DifferingPayerSchema = (foa: string) =>
  new Schema({
    ...PersonStepSchemaModel,
    firstname: { ...PersonStepSchemaModel.firstname, optional: foa === 'Firma' ? true : false },
    birthdate: { ...PersonStepSchemaModel.birthdate, optional: true },
    vsab: { ...PersonStepSchemaModel.vsab, optional: true },
    email: { ...PersonStepSchemaModel.email, optional: true },
  })
