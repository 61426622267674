import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

import { directDebitReducer } from './direct-debit.reducer'
import { ownerReducer } from './owner.reducer'

export const paymentStep = combineForms(
  {
    acceptedDirectDebit: dhvFormStateDefault.paymentStep.acceptedDirectDebit,
    directDebit: directDebitReducer,
  },
  'dhvForm.paymentStep'
)
