import moment from 'moment'
import { Schema } from 'common/dots-schema'

export const OwnerSchemaModel = {
  foa: {
    type: String,
    max: 5,
  },
  title: {
    type: String,
    max: 70,
    optional: true,
  },
  name: {
    type: String,
    max: 70,
  },
  firstname: {
    type: String,
    max: 70,
    regEx: /^[^()[\]{}*&^%$#@!0-9]*$/,
  },
  birthdate: {
    type: Date,
    custom: (value: Date) => {
      if (value instanceof Date && moment(value).add(moment.duration(18, 'years')).isAfter(moment())) {
        return 'age must be at leat 18'
      }
      return null
    },
  },
  street: {
    type: String,
    max: 70,
  },
  postalCode: {
    type: String,
    max: 5,
    min: 5,
  },
  city: {
    type: String,
    max: 60,
  },
  phone: {
    type: String,
    max: 20,
    optional: true,
    regEx: /^(:?\+|0)[\/\d\s()]*$/,
  },
  email: {
    type: String,
    max: 70,
    regEx: Schema.RegEx.Email,
    optional: true,
  },
}

export const OwnerSchema = new Schema(OwnerSchemaModel)
