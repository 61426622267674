import 'moment/locale/de'
import moment from 'moment'
moment.locale('de')

import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
registerLocale('de', de)
setDefaultLocale('de')

import { DhvForm } from 'dhv-form'
import { store } from './store'
import { App } from './main.component'

import 'react-spinner/react-spinner.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'styles/react-bootstrap-fix.css'
import '@zurich/web-sdk/css/styles.min.css'
import 'styles/main.css'

const appNode: any = document.querySelector('.app')

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <DhvForm />
      </App>
    </BrowserRouter>
  </Provider>,
  appNode
)

export const __unload = () => {
  ReactDOM.unmountComponentAtNode(appNode)
}
