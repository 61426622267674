import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'
import { priceBrutto, priceNetto, priceTax } from './kasko-price.reducer'

export const kaskoReducer = combineForms(
  {
    dronePrice: dhvFormStateDefault.rateStep.kasko.dronePrice,
    droneLending: dhvFormStateDefault.rateStep.kasko.droneLending,
    priceBrutto,
    priceNetto,
    priceTax,
  },
  'dhvForm.rateStep.kasko'
)
