import React from 'react'

interface Props {
  onChange: (event: any) => any
  onFocus: () => any
  onKeyPress: () => any
  onBlur: () => any
  id: string
  label: string
  labelLong: string
  checked: boolean
  strong?: boolean
  hasError?: boolean
  errorText?: boolean
  disabled?: boolean
  hasHelper?: boolean
  children?: any
}

interface State {}

export class Checkbox extends React.Component<Props, State> {
  render() {
    const { hasError = false, disabled = false, errorText = '', hasHelper = false, labelLong = this.props.label } = this.props

    return (
      <label className={`checkbox ${hasError ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}>
        <input
          id={this.props.id}
          name={this.props.id}
          className="checkbox-control"
          type="checkbox"
          value={this.props.label}
          checked={this.props.checked}
          disabled={disabled}
          onChange={(e) => {}}
          onClick={this.props.onChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        {this.props.children ? (
          <span className={`checkbox-label ${labelLong ? 'longtext' : ''}`} /*for={this.props.id}*/>{this.props.children}</span>
        ) : (
          <span className={`checkbox-label ${labelLong ? 'longtext' : ''}`} /*for={this.props.id}*/>
            {this.props.strong ? <strong>{this.props.label}</strong> : this.props.label}
          </span>
        )}
        {hasHelper ? '<span class="checkbox-helper-message ">' + errorText + '</span>' : ''}
      </label>
    )
  }
}
