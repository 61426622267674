import { Schema } from 'common/dots-schema'

export const KaskoCalculateSchemaModel = {
  dronePrice: {
    type: String,
    regEx: /^\d+\.?\d*(:?,?\d{1,2})?$/,
  },
  droneLending: {
    type: Boolean,
  },
}

export const KaskoCheckoutSchemaModel = {
  ...KaskoCalculateSchemaModel,
  priceBrutto: {
    type: Number,
  },
  priceTax: {
    type: Number,
  },
  priceNetto: {
    type: Number,
  },
}

export const KaskoCalculateSchema = new Schema(KaskoCalculateSchemaModel)
export const KaskoCheckoutSchema = new Schema(KaskoCheckoutSchemaModel)
