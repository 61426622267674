import update from 'immutability-helper'

import { dhvFormStateDefault } from 'dhv-common'
import { SET_STEP } from '../actions/set-step.action'
import { SET_ACTIVE_STEP } from '../actions/set-active-step.action'
import { Steps } from '../dhv-form.state'
import { FINISH_FORM_SUCCESS } from 'client/dhv-form/api/client/actions/finish.action'

export function steps(state: Steps = dhvFormStateDefault.steps, action: any): Steps {
  if (!state.locked) {
    switch (action.type) {
      case SET_STEP:
        return update(state, {
          $set: {
            ...state,
            current: action.step, // > state.current ? action.step : state.current,
            active: action.step,
          },
        })
      case SET_ACTIVE_STEP:
        return update(state, {
          active: {
            $set: action.step <= state.current ? action.step : state.current,
          },
        })
      case FINISH_FORM_SUCCESS:
        if (action.payload && action.payload.data && action.payload.data.status === 200) {
          return update(state, {
            $set: {
              locked: true,
              current: 6,
              active: 6,
            },
          })
        } else {
          return state
        }
      default:
        return state
    }
  } else {
    return state
  }
}
