import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { DownloadStep as DownloadStepComponent } from '../../ui/client/download-step.component'

import { setStep } from 'dhv-form'
import { clickedDownload } from './actions/clicked-download.action'

import { AppState } from 'dhv-common'

const mapStateToProps = (state: AppState) => ({
  rateStep: state.dhvForm.rateStep,
  downloadStep: state.dhvForm.downloadStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      onDownloadClick: clickedDownload,
      setStep,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const DownloadStep = connect(mapStateToProps, mapDispatchToProps)(DownloadStepComponent)
