import './payment-step.css'

import React from 'react'

import { Form, Control, Errors } from 'react-redux-form'
import { withRouter, RouteComponentProps } from 'react-router'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { ActionCreator } from 'redux'

import { FieldSet, FieldSetContent, FormGroup, Text, Checkbox, Radio } from 'zur-ui-forms'
import { SetStepAction } from 'dhv-form'
import { RateStepState } from 'dhv-form-rate'
import { PersonStepState } from 'dhv-form-person'
import { currencyFormatter } from 'dhv-common'
import { DirectDebitSchema } from 'zur-payments'

import { OwnerSchema } from '../../api/client/state/owner.schema'
import { PaymentStep as PaymentStepState } from '../../api/client/state/payment-step.state'
import { controlColumnsFull } from 'zur-ui-forms/columns-config'

interface PaymentStepProps extends RouteComponentProps<{}> {
  rateStep: RateStepState
  personStep: PersonStepState
  paymentStep: PaymentStepState
  setStep: ActionCreator<SetStepAction>
}

class PaymentStep extends React.Component<PaymentStepProps, {}> {
  showError = (field: any) => {
    return field.touched && !field.valid
  }

  onSubmit = () => {
    this.props.setStep(4)
    this.props.history.push('/vertragsunterlagen')
    return false
  }

  back = () => {
    this.props.history.push('/allgemeine-angaben')
  }

  parseBic = (value: string) => {
    return value ? value.toUpperCase() : value
  }

  render() {
    const { paymentStep, rateStep } = this.props

    const ownerValidators = OwnerSchema.getValidators(paymentStep.directDebit.owner, {
      context: paymentStep.directDebit.owner,
      autoClean: true,
    })
    const directDebitValidators = DirectDebitSchema.getValidators(paymentStep.directDebit, {
      context: paymentStep.directDebit,
      autoClean: true,
    })

    return (
      <div>
        <Form
          model="dhvForm.paymentStep"
          className="form-horizontal insurance-form"
          onSubmit={this.onSubmit}
          validators={{
            '': {
              selectedOwner: (val) => val.sameOwner !== '',
            },
            acceptedDirectDebit: {
              acceptedDirectDebit: (val) => val,
            },
          }}
        >
          <FieldSet title="Bezahlung">
            <FieldSetContent>
              <FormGroup boldLabel={true} label="Betrag">
                <FormControl.Static>
                  <strong>{currencyFormatter(rateStep.kasko.priceBrutto + rateStep.haftpflicht.priceBrutto)}</strong>
                </FormControl.Static>
              </FormGroup>
              <FormGroup error={this.showError(paymentStep.directDebit.forms.iban)}>
                <Text
                  model="dhvForm.paymentStep.directDebit.iban"
                  label="IBAN*"
                  name="directDebit_iban"
                  messages={{
                    required: 'Bitte eine gültige IBAN angeben',
                    custom: 'Keine gültige IBAN',
                  }}
                  validators={directDebitValidators.iban}
                />
              </FormGroup>
              <FormGroup error={this.showError(paymentStep.directDebit.forms.bic)}>
                <Text
                  model="dhvForm.paymentStep.directDebit.bic"
                  label="BIC"
                  name="directDebit_bic"
                  parser={this.parseBic}
                  messages={{
                    custom: 'Keine gültige BIC',
                  }}
                />
              </FormGroup>
              <FormGroup controlColumns={controlColumnsFull}>
                <Control.checkbox
                  model=".acceptedDirectDebit"
                  component={Checkbox}
                  validators={directDebitValidators.acceptedDirectDebit}
                  controlProps={{
                    id: 'accepted',
                    label: (
                      <span>
                        {`Ich/Wir ermächtige(n) den Zahlungsempfänger, die Zahlung von meinem/unserem Konto mittels Lastschrift einzuziehen.
                                                  Zugleich weise ich/wir mein/unser Kreditinstitut an, die von dem Zahlungsempfänger auf mein/unser Konto gezogene
                                                  Lastschrift einzulösen. Hinweis: Ich/Wir kann/können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum,
                                                  die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/unserem Kreditinstitut
                                                  vereinbarten Bedingungen.`}
                        <br />
                        <br />
                        {'Gläubiger-Identifikationsnummer: DE 38ZZZ00000023244'}
                      </span>
                    ),
                    checked: paymentStep.acceptedDirectDebit,
                  }}
                />
                <Errors
                  model="dhvForm.paymentStep.acceptedDirectDebit"
                  messages={{
                    acceptedDirectDebit: 'Sie müssen dem SEPA Mandat zustimmen, um fortfahren zu können',
                  }}
                  component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
                  show={(field: any) => {
                    return field.touched
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Stimmen Versicherungsnehmer und Kontoinhaber überein?*"
                error={this.showError(paymentStep.forms.directDebit.sameOwner)}
              >
                <Control.radio
                  model=".directDebit.sameOwner"
                  value={paymentStep.directDebit.sameOwner}
                  name="directDebit.sameOwner"
                  component={Radio}
                  errors={directDebitValidators.sameOwner}
                  controlProps={{
                    options: [
                      { label: 'Ja', value: 'ja' },
                      { label: 'Nein', value: 'nein' },
                    ],
                    messages: {
                      required: 'Bitte wählen',
                    },
                  }}
                />
              </FormGroup>
            </FieldSetContent>
          </FieldSet>
          {paymentStep.directDebit.sameOwner === 'nein' && (
            <FieldSet title="Kontoinhaber">
              <FieldSetContent>
                <FormGroup label="Anrede*" error={this.showError(paymentStep.forms.directDebit.owner.foa)}>
                  <Control.radio
                    model=".directDebit.owner.foa"
                    value={paymentStep.directDebit.owner.foa}
                    name="direct.Debit.owner.foa"
                    component={Radio}
                    errors={ownerValidators.foa}
                    controlProps={{
                      options: [
                        { label: 'Herr', value: 'Herr' },
                        { label: 'Frau', value: 'Frau' },
                        { label: 'Firma', value: 'Firma' },
                      ],
                      messages: {
                        required: 'Bitte wählen',
                      },
                    }}
                  />
                </FormGroup>
                {(paymentStep.directDebit.owner.foa === 'Herr' || paymentStep.directDebit.owner.foa === 'Frau') && (
                  <div>
                    <FormGroup>
                      <Text model="dhvForm.paymentStep.directDebit.owner.title" label="Titel" name="directDebit.owner.title" />
                    </FormGroup>
                    <FormGroup error={this.showError(paymentStep.forms.directDebit.owner.firstname)}>
                      <Text
                        model="dhvForm.paymentStep.directDebit.owner.firstname"
                        label="Vorname*"
                        name="directDebit.owner.firstname"
                        validators={ownerValidators.firstname}
                        messages={{
                          required: 'Bitte einen Vornamen angeben',
                          max: 'Der Vorname darf nicht länger als 70 Zeichen sein',
                          regEx: 'Der Vorname darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
                <FormGroup error={this.showError(paymentStep.forms.directDebit.owner.name)}>
                  <Text
                    model="dhvForm.paymentStep.directDebit.owner.name"
                    label={paymentStep.directDebit.owner.foa === 'Firma' ? 'Firmenname*' : 'Name*'}
                    name="directDebit.owner.name"
                    validators={ownerValidators.name}
                    messages={{
                      required: 'Bitte einen Namen angeben',
                      max: 'Der Name darf nicht länger als 70 Zeichen sein',
                      regEx: 'Der Name darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
                    }}
                  />
                </FormGroup>
                <FormGroup error={this.showError(paymentStep.forms.directDebit.owner.street)}>
                  <Text
                    model="dhvForm.paymentStep.directDebit.owner.street"
                    label="Straße / Nr.*"
                    name="directDebit.owner.street"
                    validators={ownerValidators.street}
                    messages={{
                      required: 'Bitte Straße und Hausnummer angeben',
                    }}
                  />
                </FormGroup>
                <FormGroup error={this.showError(paymentStep.forms.directDebit.owner.postalCode)}>
                  <Text
                    model="dhvForm.paymentStep.directDebit.owner.postalCode"
                    label="PLZ*"
                    name="directDebit.owner.postalCode"
                    validators={ownerValidators.postalCode}
                    messages={{
                      required: 'Bitte eine gültige Postleitzahl angeben',
                      type: 'Bitte eine gültige Postleitzahl angeben',
                      min: 'Bitte eine gültige deutsche Postleitzahl angeben',
                      max: 'Bitte eine gültige deutsche Postleitzahl angeben',
                    }}
                  />
                </FormGroup>
                <FormGroup error={this.showError(paymentStep.forms.directDebit.owner.city)}>
                  <Text
                    model="dhvForm.paymentStep.directDebit.owner.city"
                    label="Ort*"
                    name="directDebit.owner.city"
                    validators={ownerValidators.city}
                    messages={{
                      required: 'Bitte einen Wohnort angeben',
                    }}
                  />
                </FormGroup>
              </FieldSetContent>
            </FieldSet>
          )}
          <p className="sub-note">mit * gekennzeichnete Felder sind Pflichtfelder</p>
          <FieldSet>
            <button className="btn btn--text btn-prev float-left" type="button" onClick={this.back}>
              Zurück
              <span className="icon icon--arrow-left_24" />
            </button>
            <button className="btn btn--primary btn-next float-right" type="submit">
              <span className="icon i-cta" />
              Weiter
            </button>
          </FieldSet>
        </Form>
      </div>
    )
  }
}

const wrapped = withRouter<PaymentStepProps>(PaymentStep)
export { wrapped as PaymentStep }
