import React, { useEffect, useState } from 'react'
import './header.css'

export function Header() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header data-testid="header" className={`${scrollPosition === 0 ? '' : 'is-sticky'}`}>
      <div className="container container-left img-container">
        <a id="logo" href="https://zurich.de/">
          <img src="/images/zurich-logo-ranged-br.png" alt="Zurich Logo" />
        </a>
      </div>
    </header>
  )
}
