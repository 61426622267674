import React from 'react'
import moment from 'moment'
import { Fieldset, Errors } from 'react-redux-form'
import { FormControl, Col, ControlLabel, Button, HelpBlock } from 'react-bootstrap'

import { FormGroup, NameFields, AddressFields, Text } from 'zur-ui-forms'
import { DatePicker } from 'zur-ui-datepicker'

interface Props {
  model: string
  errors: any
  validators: any
  includeAddress?: boolean
  includeContact?: boolean
  includeBirthdate?: boolean
  contactRequired?: boolean
}

export class PersonFields extends React.Component<Props, {}> {
  render() {
    const { model, errors, validators, includeAddress, includeContact, includeBirthdate, contactRequired } = this.props
    return (
      <Fieldset model={model}>
        <NameFields errors={errors} validators={validators} />
        {includeBirthdate && (
          <FormGroup
            label="Geburtsdatum*"
            error={errors.birthdate}
            labelColumns={{ sm: 3, lg: 2 }}
            controlColumns={{ sm: 3, lg: 2 }}
          >
            <DatePicker
              model=".birthdate"
              validators={validators.birthdate}
              messages={{
                required: 'Bitte ein Geburtsdatum angeben',
                custom: 'Das Alter muss mindestens 18 betragen.',
              }}
              maxDate={moment().subtract(18, 'year').toDate()}
            />
          </FormGroup>
        )}
        {includeAddress && <AddressFields errors={errors} validators={validators} />}
        {includeContact && (
          <div>
            <FormGroup
              label={`Telefon${contactRequired ? '*' : ''}`}
              error={errors.phone}
              labelColumns={{ sm: 3, lg: 2 }}
              controlColumns={{ sm: 8, lg: 9 }}
            >
              <Text
                name="phone"
                validators={validators.phone}
                messages={{
                  required: 'Bitte eine Telefonnummer angeben',
                  regEx:
                    'Die Telefonnummer darf nur die Zeichen 0-9, "/", "(", ")" enthalten und muss mit "+" oder "0" beginnen.',
                }}
              />
            </FormGroup>
            <FormGroup
              label={`E-Mail${contactRequired ? '*' : ''}`}
              error={errors.email}
              labelColumns={{ sm: 3, lg: 2 }}
              controlColumns={{ sm: 8, lg: 9 }}
            >
              <Text
                name="email"
                validators={validators.email}
                messages={{
                  required: 'Bitte eine gültige E-Mailadresse angeben',
                  regEx: 'Bitte eine gültige E-Mailadresse angeben',
                }}
              />
            </FormGroup>
          </div>
        )}
      </Fieldset>
    )
  }
}
