import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { AppState } from 'dhv-common'
import { setStep } from 'dhv-form'

import { ContractStep as ContractStepComponent } from '../../ui/client/contract-step.component'
import { setLocked } from './actions/set-locked.action'
import { setUnlocked } from './actions/set-unlocked.action'

const mapStateToProps = (state: AppState) => ({
  scope: state.dhvForm.rateStep.scope,
  business: state.dhvForm.rateStep.usage === 'g',
  contractStep: state.dhvForm.contractStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
      setLocked,
      setUnlocked,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const ContractStep = connect(mapStateToProps, mapDispatchToProps)(ContractStepComponent)
