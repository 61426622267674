import { ActionCreator } from 'redux'
export interface SetActiveStepAction {
  type: 'SET_ACTIVE_STEP'
  step: number
}
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const setActiveStep: ActionCreator<SetActiveStepAction> = (step: number) => {
  return {
    type: SET_ACTIVE_STEP,
    step: step,
  }
}
