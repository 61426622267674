import { ActionCreator } from 'redux'
export interface SetUnlockedAction {
  type: 'SET_UN_LOCKED'
}
export const SET_UN_LOCKED = 'SET_UN_LOCKED'
export const setUnlocked: ActionCreator<SetUnlockedAction> = () => {
  return {
    type: SET_UN_LOCKED,
  }
}
