import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

export const completeStepReducer = combineForms(
  {
    acceptedContact: false,
    acceptedPhone: false,
    acceptedEmail: false,
    acceptedMobile: false,
    acceptedSms: false,
    brokerId: dhvFormStateDefault.completeStep.brokerId,
    brokerEmail: dhvFormStateDefault.completeStep.brokerEmail,
    brokerEmail2: dhvFormStateDefault.completeStep.brokerEmail2,
    milesAndMore: dhvFormStateDefault.completeStep.milesAndMore,
  },
  'dhvForm.completeStep'
)
