import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { PaymentStep as PaymentStepComponent } from '../../ui/client/payment-step.component'

import { AppState } from 'dhv-common'
import { setStep } from 'dhv-form'

const mapStateToProps = (state: AppState) => ({
  rateStep: state.dhvForm.rateStep,
  paymentStep: state.dhvForm.paymentStep,
  personStep: state.dhvForm.personStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const PaymentStep = connect(mapStateToProps, mapDispatchToProps)(PaymentStepComponent)
