import './rate-step.css'

import React from 'react'
import { ActionCreator } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Control, Errors, Actions } from 'react-redux-form'
import { FormControl, HelpBlock, Tooltip, OverlayTrigger } from 'react-bootstrap'

import { FieldSet, FieldSetContent, FormGroup, Static, Radio, Text } from 'zur-ui-forms'
import { currencyFormatter } from 'dhv-common'
import { SetActiveStepAction, SetStepAction } from 'dhv-form'
import { RateStepState } from 'dhv-form-rate'

import { RateStepSchema } from '../../api/client/state/rate-step.schema'
import { KaskoCalculateSchema } from '../../api/client/state/kasko.schema'
import Spinner from 'react-spinner'
import { Haftpflicht } from 'client/dhv-form-rate/api/client/state/haftpflicht.state'
import { Kasko } from 'client/dhv-form-rate/api/client/state/kasko.state'
import { ROUTES } from 'client/dhv-form/ui/client/dhv-form.component'
import { Select } from 'client/zur-ui-forms/ui/select/select.component'
import { Steps } from 'client/dhv-form/api/client/dhv-form.state'

interface RateStepProps extends RouteComponentProps<{}> {
  steps: Steps
  business: boolean
  rateStep: RateStepState
  calculatePrice: (scope: string, business: boolean, haftpflicht: Haftpflicht | null, kasko: Kasko | null) => Promise<any>
  changeAndResetPrice: Actions['change']
  changeUsage: Actions['change']
  setStep: ActionCreator<SetStepAction>
  setActiveStep: ActionCreator<SetActiveStepAction>
}

interface UIState {}

const LabelCoverage = () => {
  return (
    <span>
      <span className="invisible">Geltungsbereich des Versicherungsschutzes</span>
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            Die Auswahl „Europa inkl. Mittelanrainerstaaten“ gilt für alle Länder, die geographisch in Europa (bis zum Ural)
            liegen oder direkte Verbindung zum Mittelmeer haben (beispielsweise Nordafrikanische Staaten). Der weltweite
            Versicherungsschutz schließt lediglich die USA und Kanada aus, in denen wir grundsätzlich keinen
            Haftpflichtversicherungsschutz für Ihre Drohne anbieten.
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}

const LabelCoverageKasko = () => {
  return (
    <span>
      <span className="invisible">Geltungsbereich des Versicherungsschutzes</span>
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            Innerhalb der Mulitcopter-Kaskoversicherung bieten wir grundsätzlich weltweiten Versicherungsschutz.
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}
const LabelDeductibleKasko = () => {
  return (
    <span>
      <span className="invisible">Selbstbehalt</span>
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            Innerhalb der Multicopter-Kaskoversicherung bieten wir nur diesen Selbstbehalt an.
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}
const LabelInsuranceSum = () => {
  return (
    <span>
      <span className="invisible">Versicherungssumme Halterhaftpflicht</span>
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            Die Deckungssumme einer Versicherung bezeichnet den maximalen Betrag, den eine Versicherungsgesellschaft im
            Schadensfall auszahlt. Ist der Schaden höher, haften Sie mit Ihrem Privatvermögen. Wir empfehlen Ihnen daher
            grundsätzlich eine hohe Deckungssumme zu wählen, um auch bei hohen Schäden abgesichert zu sein.
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}

const LabelLending = () => {
  return (
    <span>
      Werden der Multicopter oder das Zubehör einem Dritten als Mieter, Pächter, Entleiher oder Verwahrer übergeben?
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            Wenn Sie Ihre Drohne vermieten, verleihen oder verpachten, berechnen wir für unseren Versicherungsschutz eine höhere
            Prämie, da das Risiko einer Beschädigung oder der Zerstörung des Geräts damit steigt. Mit unserem Onlinerechner können
            Sie die Unterschiede transparent berechnen.
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}

const LabelUsage = (props: { business: boolean }) => {
  return (
    <span>
      Wird der Multicopter privat oder gewerblich genutzt?
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus', 'click']}
        rootClose
        overlay={
          <Tooltip id="title-tooltip" className="infobox-msg">
            {props.business
              ? 'Sie haben im Onlinantrag angegeben, dass Sie Ihre Drohne für geschäftliche Zwecke verwenden. Gewerblich verwendet werden Multicopter/Drohnen und Flugmodelle, die nicht ausschließlich zu Zwecken des Sports oder der Freizeitgestaltung betrieben werden. Hierzu zählen auch freiberufliche Tätigkeiten oder das Veröffentlichen von Film-/Fotoaufnahmen gegen Entgelt. Die private Nutzung ist ebenfalls in den Versicherungsschutz eingeschlossen.'
              : 'Privat verwendet werden Drohnen und Flugmodelle, die ausschließlich zu Zwecken des Sports oder der Freizeitgestaltung betrieben werden. Gewerblich verwendet werden Multicopter/Drohnen, die nicht ausschließlich zu Zwecken des Sports oder der Freizeitgestaltung betrieben werden. Hierzu zählen auch freiberufliche Tätigkeiten oder das Veröffentlichen von Film-/Fotoaufnahmen gegen Entgelt.'}
          </Tooltip>
        }
      >
        <span className="icon icon--info_24_outline overlay--marginLeft" />
      </OverlayTrigger>
    </span>
  )
}

class RateStep extends React.Component<RateStepProps, UIState> {
  constructor(props: RateStepProps) {
    super(props)
    if (props.steps.locked) {
      this.props.history.push(ROUTES.THANKS)
    }
  }

  componentDidMount() {
    this.props.setActiveStep(0)
    this.props.history.listen(
      (location: { pathname: string; search: string; hash: string }, action: 'PUSH' | 'REPLACE' | 'POP') => {
        let activeStep
        if (this.props.steps.locked) {
          this.props.history.push(ROUTES.THANKS)
        } else {
          if (location.pathname === ROUTES.TARIF_RECHNER) {
            activeStep = 0
          } else if (location.pathname === ROUTES.VERTRAGS_GRUNDLAGEN) {
            activeStep = 1
          } else if (location.pathname === ROUTES.ALLGEMEINE_ANGABEN) {
            activeStep = 2
          } else if (location.pathname === ROUTES.ZAHLUNG) {
            activeStep = 3
          } else if (location.pathname === ROUTES.VERTRAGS_UNTERLAGEN) {
            activeStep = 4
          } else if (location.pathname === ROUTES.VERTRAGS_ABSCHLUSS) {
            activeStep = 5
          } else if (location.pathname === ROUTES.THANKS) {
            activeStep = 6
          } else {
            activeStep = 0
          }
          this.props.setActiveStep(activeStep)
          window.scrollTo(0, 0)
        }
      }
    )
  }

  onSubmit = () => {
    if (this.props.rateStep.scope === 'haftpflicht') {
      this.props.calculatePrice(
        this.props.rateStep.scope,
        this.props.rateStep.usage === 'g',
        this.props.rateStep.haftpflicht,
        null
      )
    }
    if (this.props.rateStep.scope === 'kasko') {
      this.props.calculatePrice(this.props.rateStep.scope, this.props.rateStep.usage === 'g', null, this.props.rateStep.kasko)
    }
    if (this.props.rateStep.scope === 'haftpflichtKasko') {
      this.props.calculatePrice(
        this.props.rateStep.scope,
        this.props.rateStep.usage === 'g',
        this.props.rateStep.haftpflicht,
        this.props.rateStep.kasko
      )
    }
    setTimeout(() => {
      window.scrollBy({
        top: 200,
        left: 0,
        behavior: 'smooth',
      })
    }, 100)
    return false
  }

  onNextClick = () => {
    this.props.setStep(1)
    this.props.history.push('/vertrags-grundlagen')
    return false
  }

  showError = (field: any) => {
    return field.touched && !field.valid
  }

  parsePrice = (value: string) => {
    return value.replace(/[^\d,]+/g, '')
  }

  render() {
    const { rateStep, changeAndResetPrice, changeUsage } = this.props
    const business = rateStep.usage === 'g'
    const validators = RateStepSchema('calculate').getValidators(rateStep, {
      context: rateStep,
      autoClean: true,
    })

    const kaskoValidators = KaskoCalculateSchema.getValidators(rateStep.kasko, {
      context: rateStep.kasko,
      autoClean: true,
    })

    return (
      <div className="container rateStep">
        <Form model="dhvForm.rateStep" className={'form-horizontal insurance-form'} onSubmit={this.onSubmit}>
          <FieldSet title="">
            <h2>Meine Multicopter- / Drohnenversicherung</h2>
          </FieldSet>
          <FieldSet title="">
            <FieldSetContent>
              <h5>Ich interessiere mich für folgenden Versicherungsschutz</h5>
              <FormGroup label={<LabelUsage business={business} />}>
                <Control.radio
                  model=".usage"
                  value={rateStep.usage}
                  changeAction={changeUsage}
                  name="usage"
                  component={Radio}
                  controlProps={{
                    options: [
                      { label: 'Gewerblich', value: 'g' },
                      { label: 'Privat', value: 'p' },
                    ],
                  }}
                />
              </FormGroup>
              <p style={{ marginTop: '-32px', marginBottom: '32px' }}>
                {business
                  ? 'Versichert gilt ein Multicopter mit einem maximalen Abfluggewicht bis 25 kg zur gewerblichen Nutzung.'
                  : 'Versichert gelten all Ihre Multicopter/Flugmodelle mit einem maximalen Abfluggewicht bis 25 kg zur privaten Nutzung.'}
              </p>
              <FormGroup error={this.showError(rateStep.forms.scope)}>
                <Select
                  disabled={!business}
                  label="Versicherungsumfang"
                  model=".scope"
                  name="scope"
                  validators={validators.scope}
                  messages={{
                    required: 'Bitte einen Versicherungsumfang auswählen',
                  }}
                  children={
                    business
                      ? [
                          <option key={0} value="" className="ss-content ss-list ss-option">
                            --Bitte auswählen--
                          </option>,
                          <option key={1} value="haftpflicht" className="ss-content ss-list ss-option">
                            Halterhaftpflicht
                          </option>,
                          <option key={2} value="kasko" className="ss-content ss-list ss-option">
                            Multicopter-Kaskoversicherung
                          </option>,
                          <option key={3} value="haftpflichtKasko" className="ss-content ss-list ss-option">
                            Halterhaftpflicht und Multicopter-Kasko
                          </option>,
                        ]
                      : [
                          <option key={0} value="haftpflicht" className="ss-content ss-list ss-option">
                            Halterhaftpflicht
                          </option>,
                        ]
                  }
                  changeAction={changeAndResetPrice}
                />
              </FormGroup>
              {(rateStep.scope === 'haftpflicht' || rateStep.scope === 'haftpflichtKasko') && (
                <div>
                  <h4>Haftpflicht</h4>
                  <FormGroup label={<LabelCoverage />} icon={true}>
                    <Select
                      disabled={!business}
                      label="Geltungsbereich des Versicherungsschutzes"
                      model=".haftpflicht.coverage"
                      name="haftpflicht_coverage"
                      children={
                        business
                          ? [
                              <option key={0} value="eu">
                                Europa inkl Mittelmeeranrainerstaaten
                              </option>,
                              <option key={1} value="world">
                                Weltweit (ohne USA und Kanada)
                              </option>,
                            ]
                          : [
                              <option key={0} value="world">
                                Weltweit (ohne USA und Kanada)
                              </option>,
                            ]
                      }
                      changeAction={changeAndResetPrice}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Select
                      disabled={true}
                      label="Selbstbehalt"
                      model=".haftpflicht.deductible"
                      name="haftpflicht_deductible"
                      children={[
                        <option key={0} value="0">
                          Kein Selbstbehalt
                        </option>,
                      ]}
                      changeAction={changeAndResetPrice}
                    />
                  </FormGroup>
                  <FormGroup label={<LabelInsuranceSum />} icon={true}>
                    <Select
                      label="Versicherungssumme Halterhaftpflicht"
                      model=".haftpflicht.insuranceSum"
                      name="haftpflicht_insuranceSum"
                      children={[
                        <option key={0} value="3000000">
                          3.000.000 EUR
                        </option>,
                        <option key={1} value="5000000">
                          5.000.000 EUR
                        </option>,
                      ]}
                      changeAction={changeAndResetPrice}
                    />
                  </FormGroup>
                </div>
              )}
              {(rateStep.scope === 'kasko' || rateStep.scope === 'haftpflichtKasko') && (
                <div>
                  <h4>Kasko</h4>
                  <FormGroup error={this.showError(rateStep.forms.kasko.dronePrice)}>
                    <Text
                      model="dhvForm.rateStep.kasko.dronePrice"
                      label="Listenpreis des Multicopters inkl. Zubehör"
                      placeholder="z.B. 3000"
                      name="kasko_dronePrice"
                      changeAction={changeAndResetPrice}
                      type="tel"
                      validators={kaskoValidators.dronePrice}
                      parser={this.parsePrice}
                      messages={{
                        required: 'Bitte geben Sie den Listenpreis des Multicopters an.',
                        regEx: 'Der Listenpreis muss eine Zahl größer als 0 sein.',
                      }}
                    />
                  </FormGroup>
                  <FormGroup label={<LabelLending />}>
                    <Control.radio
                      model=".kasko.droneLending"
                      value={rateStep.kasko.droneLending ? 'Ja' : 'Nein'}
                      name="kasko_droneLending"
                      changeAction={changeAndResetPrice}
                      component={Radio}
                      controlProps={{
                        options: [
                          { label: 'Ja', value: 'Ja' },
                          { label: 'Nein', value: 'Nein' },
                        ],
                      }}
                    />
                  </FormGroup>
                  <FormGroup label={<LabelCoverageKasko />}>
                    <Select
                      disabled
                      label="Geltungsbereich des Versicherungsschutzes"
                      model="dhvForm.rateStep.kasko.coverage"
                      /* ToDo */
                      /* value="world" */
                      name="kasko_coverage"
                      children={[
                        <option key={0} value="world">
                          Weltweit
                        </option>,
                      ]}
                      changeAction={changeAndResetPrice}
                    />
                  </FormGroup>
                  <FormGroup label={<LabelDeductibleKasko />}>
                    <Select
                      disabled
                      label="Selbstbehalt"
                      model="dhvForm.rateStep.kasko.deductible"
                      /* ToDo */
                      /* value="0" */
                      name="kasko_deductible"
                      children={[
                        <option key={0} value="0">
                          10% (mindestes 250 EUR)
                        </option>,
                      ]}
                      changeAction={changeAndResetPrice}
                    />
                  </FormGroup>
                </div>
              )}
              <div className="calcButtonContainer">
                <button type="submit" disabled={rateStep.isCalculating} className="calcButton btn btn--primary">
                  Berechnen
                  {rateStep.isCalculating ? <Spinner /> : null}
                </button>
              </div>
            </FieldSetContent>
          </FieldSet>
          {rateStep.errorMessage !== '' && (
            <div className="calc-container">
              <HelpBlock className="has-error calculate-error">{rateStep.errorMessage}</HelpBlock>
            </div>
          )}
          {!rateStep.isCalculating && (rateStep.kasko.priceBrutto !== 0 || rateStep.haftpflicht.priceBrutto !== 0) && (
            <FieldSet title="Mein Jahresbeitrag">
              <FieldSetContent>
                {rateStep.haftpflicht.priceBrutto !== 0 && (
                  <FormGroup
                    boldLabel={true}
                    label={<strong>Multicopter-Haftpflicht</strong>}
                    labelColumns={{ sm: 6, xs: 6 }}
                    controlColumns={{ sm: 6, xs: 6 }}
                  >
                    <Static value={<strong>{currencyFormatter(rateStep.haftpflicht.priceBrutto)} *</strong>} />
                  </FormGroup>
                )}
                {rateStep.kasko.priceBrutto !== 0 && (
                  <FormGroup
                    boldLabel={true}
                    label={<strong>Multicopter-Kasko</strong>}
                    labelColumns={{ sm: 6, xs: 6 }}
                    controlColumns={{ sm: 6, xs: 6 }}
                  >
                    <Static value={<strong>{currencyFormatter(rateStep.kasko.priceBrutto)} *</strong>} />
                  </FormGroup>
                )}
                <button className="btn btn--tertiary btn--tertiary-peach btn-final" type="button" onClick={this.onNextClick}>
                  Jetzt online beantragen
                  <span className="icon icon--calculator_48_outline rateStep__cta-3" />
                </button>
                <p className="sub-note">
                  * Der ausgewiesene Beitrag beinhaltet die derzeit gültige Versicherungssteuer in Höhe von 19%.
                </p>
              </FieldSetContent>
            </FieldSet>
          )}
        </Form>
      </div>
    )
  }
}

const wrapped = withRouter<RateStepProps>(RateStep)
export { wrapped as RateStep }
