import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'
import React, { forwardRef } from 'react'
import DatePickerOriginal from 'react-datepicker'
import { format, isValid } from 'date-fns'
import { fixDateInputString, parseDateFromString, isValidDate } from './../../dhv-common/ui/shared/utils'

// type definitions are not matching so make this any type :-(
const DatePicker: any = DatePickerOriginal

interface Props {
  onChange: (event: any) => any

  onFocus: () => any

  onKeyPress: () => any

  onBlur: () => any

  date: Date

  value: string

  className: string

  disabled: boolean

  placeholder: string

  minDate?: Date

  maxDate?: Date
}

interface State {
  dateString: string
}

interface DatepickerInputProps {
  value: string
  onClick: () => any
}

export class ReactDatePicker extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      dateString: this.props.date && isValid(this.props.date) ? format(this.props.date, 'dd.MM.yyyy') : '',
    }
  }

  handleChange = (date?: Date, event?: React.SyntheticEvent<any>): any => {
    // React only on handleChange, when there is no value in event (because then already handleeChangeRaw was triggered)
    if (!(event?.target as any).value && date && isValidDate(date, this.props.minDate, this.props.maxDate)) {
      this.setState({ dateString: format(date, 'dd.MM.yyyy') })
      this.props.onChange(date)
    }
  }

  // Validate input string

  handleChangeRaw(value: string) {
    if (!value) {
      this.setState({ dateString: '' })
      return
    }
    value = fixDateInputString(value, this.state.dateString)
    this.setState({ dateString: value })
    if (value.length === 10) {
      this.props.onChange(parseDateFromString(value, this.props.minDate, this.props.maxDate))
    }
  }

  onBlur(value: string) {
    this.props.onChange(parseDateFromString(value, this.props.minDate, this.props.maxDate))
    this.props.onBlur()
  }

  render() {
    /* const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
      <div style={{ display: 'flex' }} onClick={props.onClick} className="textfield textfield-datepicker">
        <span style={{ cursor: 'pointer', top: '3px' }} className="icon icon--calendar-end_24_outline"></span>
        <input
          ref={ref}
          type="text"
          placeholder="tt.mm.jjjj"
          className="textfield-control datepicker-input is-not-empty react-datepicker-ignore-onclickoutside"
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    ) */

    return (
      <DatePicker
        dateFormat="dd.MM.yyyy"
        value={this.state.dateString}
        onChange={this.handleChange}
        onChangeRaw={(event: any) => this.handleChangeRaw(event.target.value)}
        onFocus={this.props.onFocus}
        onBlur={(event: any) => this.onBlur(event.nativeEvent.srcElement.value)}
        className={this.props.className}
        disabled={this.props.disabled}
        placeholderText={this.props.placeholder}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        //customInput={<CustomInput />}
        //customInput={React.createElement(React.forwardRef(CustomInput))}
      />
    )
  }
}
