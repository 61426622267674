import React from 'react'

interface Props {
  url: string
  iconName?: string
  linkText: string
  metaText?: string
}

export class DownloadLink extends React.Component<Props, {}> {
  render() {
    const { url, iconName = 'icon--download_24', linkText, metaText } = this.props

    return (
      <li>
        <a href={url} target="_blank" download="">
          <span className={'icon ' + iconName}></span>
          <span className="link-text">{linkText}</span>
        </a>
        <span className="meta-text">{metaText}</span>
      </li>
    )
  }
}
