import axios, { AxiosResponse } from 'axios'
import { CalculateResult } from 'client/dhv-form-rate/api/client/actions/prices'
import { DroneHaftpflicht } from 'drone-insurance/drone-haftpflicht'
import { DroneKasko } from 'drone-insurance/drone-kasko'

export async function calculate(
  scope: string,
  business: boolean,
  haftpflicht: DroneHaftpflicht | null,
  kasko: DroneKasko | null
): Promise<AxiosResponse<CalculateResult> | undefined> {
  if (!haftpflicht && !kasko) {
    throw new Error('Provide either haftpflicht or kasko.')
    return
  }

  let payload = {
    insurance: {
      business,
      scope,
      ...((scope === 'haftpflicht' || scope === 'haftpflichtKasko') && { haftpflicht }),
      ...((scope === 'kasko' || scope === 'haftpflichtKasko') && { kasko }),
    },
  }

  return await axios.post(`${process.env.API_HOST || ''}/api/insuranceCalculate`, payload)
}
