import React from 'react'

interface Props {}

export class FieldSetContent extends React.Component<Props, {}> {
  render() {
    const { children } = this.props
    return <div className="form-content">{children}</div>
  }
}
