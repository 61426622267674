import { dhvFormStateDefault } from 'dhv-common'
import {
  CALC_HAFTPFLICHT_PRICE_START,
  CALC_HAFTPFLICHT_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_PRICE_FAILED,
  CALC_KASKO_PRICE_START,
  CALC_KASKO_PRICE_SUCCESS,
  CALC_KASKO_PRICE_FAILED,
  CALC_HAFTPFLICHT_KASKO_PRICE_START,
  CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_KASKO_PRICE_FAILED,
} from 'client/dhv-form-rate/api/client/actions/prices'

export function errorMessage(state: string = dhvFormStateDefault.rateStep.errorMessage, action: any): string {
  switch (action.type) {
    case CALC_HAFTPFLICHT_PRICE_START:
      return ''
    case CALC_KASKO_PRICE_START:
      return ''
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return ''
    case CALC_HAFTPFLICHT_PRICE_SUCCESS:
      return ''
    case CALC_KASKO_PRICE_SUCCESS:
      return ''
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return ''
    case CALC_HAFTPFLICHT_PRICE_FAILED:
      return action.errorMessage
    case CALC_KASKO_PRICE_FAILED:
      return action.errorMessage
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return action.errorMessage
    default:
      return state
  }
}
