import React from 'react'

import './footer.css'

export function Footer() {
  return (
    <footer className="footer bg-zurichblue inverted">
      <fieldset>
        <div className="footer-inner">
          <div className="footer-top container">
            <div className="footer-links"></div>

            <div className="footer-links footer-links--horizontal"></div>

            <div className="footer-social">
              <h3>Social Media</h3>

              <div className="social-bar">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/ZurichDeutschland" className="icon icon--facebook_24_solid" />
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/showcase/zurich-gruppe-deutschland"
                      className="icon icon--linkedin_24_solid"
                    />
                  </li>
                  <li>
                    <a href="https://twitter.com/zurich_de" className="icon icon--twitter_24_solid" />
                  </li>
                  <li>
                    <a href="https://www.instagram.com/zurich_deutschland/" className="icon icon--instagram_24_solid" />
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCkVX4VWDCbteeEG0lDhGScA" className="icon icon--youtube_24_solid" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-bottom container">
            <ul className="link-list footer-legal">
              <li>
                <a href="https://zurich.de/de-de/services/impressum">Impressum</a>
              </li>
              <li>
                <a href="https://zurich.de/de-de/ueber-uns/unternehmen/werte-und-verantwortung/datenschutz">Datenschutz</a>
              </li>
              <li>
                <a href="https://zurich.de/de-de/services/nutzungsbedingungen">Nutzungsbedingungen</a>
              </li>
              <li>
                <a href="/">© 2021 Zurich</a>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>
    </footer>
  )
}
