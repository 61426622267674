import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Control, Errors } from 'react-redux-form'
import { FormGroup, Col, HelpBlock } from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import { ActionCreator } from 'redux'

import { Checkbox, FieldSet } from 'zur-ui-forms'
import { SetStepAction } from 'dhv-form'
import { RateStepState } from 'dhv-form-rate'
import { DownloadStepState } from 'dhv-form-download'
import { DownloadLink } from 'client/zur-ui-forms/ui/downloadlink/downloadlink.component'

interface DownloadStepProps extends RouteComponentProps<{}> {
  rateStep: RateStepState
  downloadStep: DownloadStepState
  onDownloadClick: any
  setStep: ActionCreator<SetStepAction>
}

function downloaded(val: any) {
  return val
}

function accepted(val: any) {
  return val
}

function received(val: any) {
  return val
}

class DownloadStep extends React.Component<DownloadStepProps, {}> {
  handleDownloadClick = (event: any) => {
    const { scope } = this.props.rateStep
    const documents =
      scope === 'haftpflicht'
        ? 'Ihre-Vertragsunterlagen-Haftpflicht.zip'
        : scope === 'kasko'
        ? 'Ihre-Vertragsunterlagen-Kasko.zip'
        : 'Ihre-Vertragsunterlagen.zip'
    window.open(documents, '_blank')
    this.props.onDownloadClick(event)
    event.preventDefault()
    return false
  }

  onSubmit = () => {
    this.props.setStep(5)
    this.props.history.push('/vertragsabschluss')
    return false
  }

  back = () => {
    this.props.history.push('/zahlungsinformationen')
  }

  render() {
    const { rateStep } = this.props
    return (
      <Form
        model="dhvForm.downloadStep"
        className="form-horizontal insurance-form"
        onSubmit={this.onSubmit}
        validators={{
          clickedDownload: { downloaded },
          readAndAccepted: { accepted },
          received: { received },
        }}
      >
        <fieldset>
          <legend>
            <h3>Wichtige Informationen für Sie</h3>
          </legend>
          <div className="form-content">
            <p>Bitte laden Sie die vertragsrelevanten Informationen mit Klick auf den blauen Button herunter.</p>
            <button className="btn btn--primary mb-3" onClick={this.handleDownloadClick}>
              <span className="icon icon--download_24 icon--right" />
              Alle Dateien herunterladen
            </button>
            <MediaQuery minWidth={767}>
              {this.props.downloadStep.clickedDownload && (
                <span
                  className="icon i-hook"
                  style={{
                    fontSize: 34,
                    color: '#003399',
                    marginLeft: 10,
                    marginTop: -5,
                  }}
                />
              )}
            </MediaQuery>
            <Errors
              model={`dhvForm.downloadStep.clickedDownload`}
              messages={{
                downloaded: 'Bitte laden Sie die vertragsrelevanten Dokumente zuerst herunter.',
              }}
              component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
              show={(field) => {
                return field.touched as boolean
              }}
            />
            <div className="download-list mb-3">
              <div className="download-links">
                {rateStep.scope === 'haftpflicht' && (
                  <React.Fragment>
                    <DownloadLink url="haftpflicht/Verbraucherinformation.pdf" linkText="Verbraucherinformationen" />
                    <DownloadLink url="haftpflicht/AVB-Luftfahrzeuge.pdf" linkText="AVB Luftfahrzeuge" />
                  </React.Fragment>
                )}
                {rateStep.scope === 'haftpflichtKasko' && (
                  <React.Fragment>
                    <DownloadLink url="haftpflicht/Verbraucherinformation.pdf" linkText="Verbraucherinformationen" />
                    <DownloadLink url="haftpflicht/AVB-Luftfahrzeuge.pdf" linkText="AVB Luftfahrzeuge" />
                    <DownloadLink url="kasko/ABE.pdf" linkText="Allgemeine Bedingungen für die Elektronikversicherung" />
                    <DownloadLink
                      url="kasko/BV-Multikopter-Kasko.pdf"
                      linkText="Besondere Vereinbarungen zur Multicopter-Kaskoversicherung"
                    />
                  </React.Fragment>
                )}
                {rateStep.scope === 'kasko' && (
                  <React.Fragment>
                    <DownloadLink url="kasko/ABE.pdf" linkText="Allgemeine Bedingungen für die Elektronikversicherung" />
                    <DownloadLink url="kasko/Allgemeine-Hinweise.pdf" linkText="Allgemeine Hinweise" />
                    <DownloadLink url="kasko/Anzeigepflicht.pdf" linkText="Anzeigepflicht" />
                    <DownloadLink url="kasko/BV-Multikopter-Kasko.pdf" linkText="Informationen zur Datenverwendung" />
                    <DownloadLink url="kasko/Informationen-Datenverwendung.pdf" linkText="Informationen zur Datenverwendung" />
                  </React.Fragment>
                )}
              </div>
            </div>
            <p style={{ marginTop: '32px' }}>
              Zum anzeigen der PDF-Dokumente können Sie hier kostenlos den{' '}
              <a target="_blank" href="https://get.adobe.com/de/reader/">
                Acrobat Reader herunterladen
              </a>
            </p>
            <FormGroup>
              <Control.checkbox
                model="dhvForm.downloadStep.received"
                component={Checkbox}
                controlProps={{
                  id: 'received',
                  children: <span>Ich habe die o.g. Unterlagen erhalten</span>,
                }}
              />
              <Errors
                model="dhvForm.downloadStep.received"
                messages={{
                  received: 'Bitte bestätigen Sie, dass Sie die o.g. Unterlagen erhalten haben.',
                }}
                component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
                show={(field) => {
                  return field.touched as boolean
                }}
              />
            </FormGroup>
            <FormGroup>
              <Control.checkbox
                model="dhvForm.downloadStep.readAndAccepted"
                component={Checkbox}
                controlProps={{
                  id: 'readAndAccepted',
                  children: <span>Ich akzeptiere die darin enthaltenen Bestimmungen</span>,
                }}
              />
              <Errors
                model="dhvForm.downloadStep.readAndAccepted"
                messages={{
                  accepted: 'Bitte bestätigen Sie, dass Sie die enthaltenen Bestimmungen aktzeptieren.',
                }}
                component={(props) => <HelpBlock className="has-error">{props.children}</HelpBlock>}
                show={(field) => {
                  return field.touched as boolean
                }}
              />
            </FormGroup>
          </div>
        </fieldset>
        <FieldSet>
          <button className="btn btn--text btn-prev float-left" type="button" onClick={this.back}>
            Zurück
            <span className="icon icon--arrow-left_24"></span>
          </button>
          <button className="btn btn--primary btn-next float-right" type="submit">
            <span className="icon i-cta" />
            Weiter
          </button>
        </FieldSet>
      </Form>
    )
  }
}

const wrapped = withRouter<DownloadStepProps>(DownloadStep)
export { wrapped as DownloadStep }
