import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'
import { priceBrutto, priceNetto, priceTax } from './haftpflicht-price.reducer'

export const haftpflichtReducer = combineForms(
  {
    coverage: dhvFormStateDefault.rateStep.haftpflicht.coverage,
    insuranceSum: dhvFormStateDefault.rateStep.haftpflicht.insuranceSum,
    deductible: dhvFormStateDefault.rateStep.haftpflicht.deductible,
    priceBrutto,
    priceNetto,
    priceTax,
  },
  'dhvForm.rateStep.haftpflicht'
)
