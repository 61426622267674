import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { PersonStep as PersonStepComponent } from '../../ui/client/person-step.component'

import { AppState } from 'dhv-common'
import { setStep } from 'dhv-form'

const mapStateToProps = (state: AppState) => ({
  personStep: state.dhvForm.personStep,
  isBusiness: state.dhvForm.rateStep.usage === 'g',
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const PersonStep = connect(mapStateToProps, mapDispatchToProps)(PersonStepComponent)
