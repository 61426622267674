import { combineForms } from 'react-redux-form'

import update from 'immutability-helper'

import { dhvFormStateDefault } from 'dhv-common'
import { CLICKED_DOWNLOAD } from '../actions/clicked-download.action'
import { DownloadStepState } from '../download-step.state'

export function clickedDownload(state: boolean = dhvFormStateDefault.downloadStep.clickedDownload, action: any): boolean {
  switch (action.type) {
    case CLICKED_DOWNLOAD:
      return action.clicked
    default:
      return state
  }
}
