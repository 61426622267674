import { dhvFormStateDefault } from 'dhv-common'
import {
  CALC_HAFTPFLICHT_KASKO_PRICE_FAILED,
  CALC_HAFTPFLICHT_KASKO_PRICE_START,
  CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS,
  CALC_KASKO_PRICE_FAILED,
  CALC_KASKO_PRICE_START,
  CALC_KASKO_PRICE_SUCCESS,
  RESET_PRICE,
} from '../actions/prices'

export function priceBrutto(state: number = dhvFormStateDefault.rateStep.kasko.priceBrutto, action: any): number {
  switch (action.type) {
    case CALC_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_KASKO_PRICE_SUCCESS:
      return action.priceBruttoKasko
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceBruttoKasko
    case CALC_KASKO_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    case RESET_PRICE:
      return 0
    default:
      return state
  }
}

export function priceNetto(state: number = dhvFormStateDefault.rateStep.kasko.priceNetto, action: any): number {
  switch (action.type) {
    case CALC_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_KASKO_PRICE_SUCCESS:
      return action.priceNettoKasko
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceNettoKasko
    case RESET_PRICE:
      return 0
    case CALC_KASKO_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    default:
      return state
  }
}

export function priceTax(state: number = dhvFormStateDefault.rateStep.kasko.priceTax, action: any): number {
  switch (action.type) {
    case CALC_KASKO_PRICE_START:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return 0
    case CALC_KASKO_PRICE_SUCCESS:
      return action.priceTaxKasko
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return action.priceTaxKasko
    case RESET_PRICE:
      return 0
    case CALC_KASKO_PRICE_FAILED:
      return 0
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return 0
    default:
      return state
  }
}
