import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

import { ownerReducer } from './owner.reducer'

export const directDebitReducer = combineForms(
  {
    iban: dhvFormStateDefault.paymentStep.directDebit.iban,
    bic: dhvFormStateDefault.paymentStep.directDebit.bic,
    owner: ownerReducer,
    sameOwner: dhvFormStateDefault.paymentStep.directDebit.sameOwner,
  },
  'dhvForm.paymentStep.directDebit'
)
