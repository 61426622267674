import { combineForms } from 'react-redux-form'

import { clickedDownload } from './reducers/clicked-download.reducer'

export const downloadStepReducer = combineForms(
  {
    clickedDownload,
    readAndAccepted: false,
    received: false,
  },
  'dhvForm.downloadStep'
)
