import { ActionCreator } from 'redux'
import { Kasko } from '../state/kasko.state'
import { Haftpflicht } from '../state/haftpflicht.state'
import { calculate } from 'client/dhv-form/api/client/actions/calculate.action'
import { DroneHaftpflicht } from 'common/drone-insurance/drone-haftpflicht'
import { DroneKasko } from 'common/drone-insurance/drone-kasko'
import { isNaN } from 'lodash'

function calcTax(price: number | undefined) {
  return price && !isNaN() ? Math.round(price * 0.19 * 100) / 100 : 0
}

export interface CalcHaftpflichtKaskoPriceAction {
  type: 'calcHaftpflichtKaskoPrice'
  priceBruttoHaftpflicht: number
  priceTaxHaftpflicht: number
  priceNettoHaftpflicht: number
  priceBruttoKasko: number
  priceTaxKasko: number
  priceNettoKasko: number
}

export const CALC_HAFTPFLICHT_PRICE_START = 'calcHaftpflichtPriceStart'
export const CALC_HAFTPFLICHT_PRICE_SUCCESS = 'calcHaftpflichtPriceSuccess'
export const CALC_HAFTPFLICHT_PRICE_FAILED = 'calcHaftpflichtPriceFailed'

export const CALC_KASKO_PRICE_START = 'calcKaskoPriceStart'
export const CALC_KASKO_PRICE_SUCCESS = 'calcKaskoPriceSuccess'
export const CALC_KASKO_PRICE_FAILED = 'calcKaskoPriceFailed'

export const CALC_HAFTPFLICHT_KASKO_PRICE_START = 'calcHaftpflichtKaskoPriceStart'
export const CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS = 'calcHaftpflichtKaskoPriceSuccess'
export const CALC_HAFTPFLICHT_KASKO_PRICE_FAILED = 'calcHaftpflichtKaskoPriceFailed'

export interface CalculateResult {
  status: number
  errorMessage: string
  price: {
    priceNetto: number
    priceTax: number
    priceBrutto: number
  }
  priceHaftpflicht: {
    priceNetto: number
    priceTax: number
    priceBrutto: number
  } | null
  priceKasko: {
    priceNetto: number
    priceTax: number
    priceBrutto: number
  } | null
}

const createActionFailed = (haftpflicht: Haftpflicht | null, kasko: Kasko | null) => {
  return {
    type:
      haftpflicht && kasko
        ? CALC_HAFTPFLICHT_KASKO_PRICE_FAILED
        : haftpflicht
        ? CALC_HAFTPFLICHT_PRICE_FAILED
        : CALC_KASKO_PRICE_FAILED,
    priceBruttoHaftpflicht: 0,
    priceTaxHaftpflicht: 0,
    priceNettoHaftpflicht: 0,
    priceBruttoKasko: 0,
    priceTaxKasko: 0,
    priceNettoKasko: 0,
    errorMessage: 'Fehler bei der Berechnung. Bitte versuchen Sie es später noch einmal.',
  }
}

export const calculatePrice =
  async (scope: string, business: boolean, haftpflicht: Haftpflicht | null, kasko: Kasko | null): Promise<any> =>
  async (dispatch: any) => {
    if (!haftpflicht && !kasko) {
      dispatch(createActionFailed(haftpflicht, kasko))
    }
    try {
      dispatch({
        type:
          haftpflicht && kasko
            ? CALC_HAFTPFLICHT_KASKO_PRICE_START
            : haftpflicht
            ? CALC_HAFTPFLICHT_PRICE_START
            : CALC_KASKO_PRICE_START,
        priceBruttoHaftpflicht: 0,
        priceTaxHaftpflicht: 0,
        priceNettoHaftpflicht: 0,
        priceBruttoKasko: 0,
        priceTaxKasko: 0,
        priceNettoKasko: 0,
        errorMessage: '',
      })

      // Remove forms key from payload and assign new type
      // Haftpflicht --> DroneHaftpflicht and Kasko --> DroneKasko
      let haftpflichtPayload: DroneHaftpflicht | null = null
      let kaskoPayload: DroneKasko | null = null
      if (haftpflicht) {
        haftpflichtPayload = (({ forms, ...obj }) => obj)(haftpflicht)
      }
      if (kasko) {
        kaskoPayload = (({ forms, ...obj }) => obj)(kasko)
      }

      let result = await calculate(scope, business, haftpflichtPayload, kaskoPayload)

      if (result && result.data && result.data.status === 200) {
        const prices = result.data as CalculateResult
        dispatch({
          type:
            haftpflicht && kasko
              ? CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS
              : haftpflicht
              ? CALC_HAFTPFLICHT_PRICE_SUCCESS
              : CALC_KASKO_PRICE_SUCCESS,
          priceBruttoHaftpflicht: prices?.priceHaftpflicht?.priceBrutto || 0,
          priceTaxHaftpflicht: prices?.priceHaftpflicht?.priceTax || 0,
          priceNettoHaftpflicht: prices?.priceHaftpflicht?.priceNetto || 0,
          priceBruttoKasko: prices?.priceKasko?.priceBrutto || 0,
          priceTaxKasko: prices?.priceKasko?.priceTax || 0,
          priceNettoKasko: prices?.priceKasko?.priceNetto || 0,
          errorMessage: '',
        })
      } else {
        dispatch(createActionFailed(haftpflicht, kasko))
      }
    } catch (e) {
      console.log(e)
      dispatch(createActionFailed(haftpflicht, kasko))
    }
  }

export interface ResetPriceAction {
  type: 'resetPrice'
}

export const RESET_PRICE = 'resetPrice'

export const resetPrice: ActionCreator<ResetPriceAction> = () => {
  return {
    type: RESET_PRICE,
  }
}
