import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'

import { haftpflichtReducer as haftpflicht } from './haftpflicht.reducer'
import { kaskoReducer as kasko } from './kasko.reducer'
import { isCalculating } from './isCalculating.reducer'
import { errorMessage } from './errorMessage.reducer'

export const rateStepReducer = combineForms(
  {
    usage: dhvFormStateDefault.rateStep.usage,
    scope: dhvFormStateDefault.rateStep.scope,
    haftpflicht,
    kasko,
    isCalculating,
    errorMessage,
  },
  'dhvForm.rateStep'
)
