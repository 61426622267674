import { dhvFormStateDefault } from 'dhv-common'
import {
  CALC_HAFTPFLICHT_PRICE_START,
  CALC_HAFTPFLICHT_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_PRICE_FAILED,
  CALC_KASKO_PRICE_START,
  CALC_KASKO_PRICE_SUCCESS,
  CALC_KASKO_PRICE_FAILED,
  CALC_HAFTPFLICHT_KASKO_PRICE_START,
  CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS,
  CALC_HAFTPFLICHT_KASKO_PRICE_FAILED,
} from 'client/dhv-form-rate/api/client/actions/prices'

export function isCalculating(state: boolean = dhvFormStateDefault.rateStep.isCalculating, action: any): boolean {
  switch (action.type) {
    case CALC_HAFTPFLICHT_PRICE_START:
      return true
    case CALC_KASKO_PRICE_START:
      return true
    case CALC_HAFTPFLICHT_KASKO_PRICE_START:
      return true
    case CALC_HAFTPFLICHT_PRICE_SUCCESS:
      return false
    case CALC_KASKO_PRICE_SUCCESS:
      return false
    case CALC_HAFTPFLICHT_KASKO_PRICE_SUCCESS:
      return false
    case CALC_HAFTPFLICHT_PRICE_FAILED:
      return false
    case CALC_KASKO_PRICE_FAILED:
      return false
    case CALC_HAFTPFLICHT_KASKO_PRICE_FAILED:
      return false
    default:
      return state
  }
}
