import React from 'react'

import { FormGroup, FormGroupMulti, Text } from 'zur-ui-forms'

interface Props {
  errors: any
  validators: any
}

export class AddressFields extends React.Component<Props, {}> {
  render() {
    const { errors, validators } = this.props
    return (
      <div>
        <FormGroup label="Straße / Nr.*" error={errors.street}>
          <Text
            name="street"
            validators={validators.street}
            messages={{
              required: 'Bitte Straße und Hausnummer angeben',
            }}
          />
        </FormGroup>
        <FormGroupMulti
          groups={[
            {
              label: 'PLZ*',
              error: errors.postalCode,
              control: (
                <Text
                  name="postalCode"
                  validators={validators.postalCode}
                  messages={{
                    required: 'Bitte eine gültige Postleitzahl angeben',
                    type: 'Bitte eine gültige Postleitzahl angeben',
                    min: 'Bitte eine gültige deutsche Postleitzahl angeben',
                    max: 'Bitte eine gültige deutsche Postleitzahl angeben',
                  }}
                />
              ),
              labelColumns: { sm: 3, lg: 2 },
              controlColumns: { sm: 2, lg: 2 },
            },
            {
              label: 'Ort*',
              error: errors.city,
              control: (
                <Text
                  name="city"
                  validators={validators.city}
                  messages={{
                    required: 'Bitte einen Wohnort angeben',
                  }}
                />
              ),
              labelColumns: { sm: 2, lg: 2 },
              controlColumns: { sm: 8, lg: 5 },
            },
          ]}
        />
      </div>
    )
  }
}
