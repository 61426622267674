import React from 'react'
import { Errors } from 'react-redux-form'
import { HelpBlock } from 'react-bootstrap'

interface Props {
  options: Array<{ label: string; value: any }>
  name: string
  onChange?: any
  checked?: any
  value?: any
  changeAction?: Function
  model?: string
  validators?: any
  messages?: any
  disabled?: boolean
}

export class Radio extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  onChange = (model: string, value: any) => {
    return (event: any) => {
      this.props.onChange(event)
    }
  }

  render() {
    const { options, name, validators, messages, changeAction, onChange, disabled } = this.props
    const value = typeof this.props.value !== 'undefined' ? this.props.value.toString() : undefined
    const model = this.props.model ? this.props.model : `.${name}`
    return (
      <div>
        {options.map((option, index) => {
          const id = `${name}_${option.value}`.replace(/\./gi, '_')
          const uniqueName = `${name}`
          return (
            <label className="radio" key={index} htmlFor={id}>
              <input
                className="radio-control"
                disabled={disabled}
                checked={value === option.value.toString()}
                type="radio"
                name={uniqueName}
                value={option.value.toString()}
                id={id}
                onChange={() => null}
                onClick={this.onChange(model, option.value)}
              />
              <span className="radio-label">{option.label}</span>
            </label>
          )
        })}
        {messages && (
          <Errors
            model={model}
            messages={messages}
            component={(props) => <HelpBlock>{props.children}</HelpBlock>}
            show={(field) => {
              return field.touched as boolean
            }}
          />
        )}
      </div>
    )
  }
}
