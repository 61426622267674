import { Schema } from 'common/dots-schema'
import { KaskoCalculateSchema, KaskoCheckoutSchema } from './kasko.schema'
import { HaftpflichtCalculateSchema, HaftpflichtCheckoutSchema } from './haftpflicht.schema'
import { ModelType } from 'drone-insurance'

export const RateStepSchemaModel = (modelType: string) => {
  return {
    business: {
      type: Boolean,
    },
    scope: {
      type: String,
      regEx: Schema.RegEx.Scope,
    },
    brokerId: {
      type: String,
      optional: true,
      min: 9,
      max: 9,
    },
    brokerEmail: {
      type: String,
      optional: true,
      regEx: Schema.RegEx.Email,
    },
    brokerEmail2: {
      type: String,
      optional: true,
      regEx: Schema.RegEx.Email,
    },
    kasko: {
      type: modelType === 'calculate' ? KaskoCalculateSchema : KaskoCheckoutSchema,
      optional: true,
    },
    haftpflicht: {
      type: modelType === 'calculate' ? HaftpflichtCalculateSchema : HaftpflichtCheckoutSchema,
      optional: true,
    },
  }
}

export const RateStepSchema = (modalType: ModelType) => new Schema(RateStepSchemaModel(modalType))
