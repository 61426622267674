import { ActionCreator } from 'redux'
export interface SetStepAction {
  type: 'SET_STEP'
  step: number
}
export const SET_STEP = 'SET_STEP'
export const setStep: ActionCreator<SetStepAction> = (step: number) => {
  return {
    type: SET_STEP,
    step: step,
  }
}
