import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { withRouter } from 'react-router'

import { DhvForm as DhvFormComponent } from '../../ui/client/dhv-form.component'

import { AppState } from 'dhv-common'
import { setStep, setActiveStep } from 'dhv-form'

const mapStateToProps = (state: AppState) => ({
  steps: state.dhvForm.steps,
  finish: state.dhvForm.finish,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
      setActiveStep,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const DhvForm = connect(mapStateToProps, mapDispatchToProps)(DhvFormComponent)
